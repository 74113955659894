import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getParticipantGroups, getParticipantUsers } from './thunks'
import {
  ParticipantGroupsState,
  ParticipantGroupsByTypeState,
  ParticipantGroupsSearch,
  ParticipantUsersSearch,
} from './types'
import { GroupType } from 'store/types/enums'

export const ParticipantGroupsPageSize = 20

const initialState: ParticipantGroupsState = {
  groupsByType: <ParticipantGroupsByTypeState[]>[
    {
      groupType: GroupType.Department,
      groups: [],
      nextPage: 1,
      total: 0,
      totalWithChildren: 0,
      pageSize: ParticipantGroupsPageSize,
      isLoading: false,
      error: null,
    },
    {
      groupType: GroupType.Project,
      groups: [],
      nextPage: 1,
      total: 0,
      totalWithChildren: 0,
      pageSize: ParticipantGroupsPageSize,
      isLoading: false,
      error: null,
    },
    {
      groupType: GroupType.IndividualUsers,
      groups: [],
      nextPage: 1,
      total: 0,
      totalWithChildren: 0,
      pageSize: ParticipantGroupsPageSize,
      isLoading: false,
      error: null,
    },
  ],
  participantData: {
    users: [],
    nextPage: 1,
    total: 0,
    pageSize: ParticipantGroupsPageSize,
    isLoading: false,
    error: null,
  },
}

const participantGroupsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearGroups: (state, action: PayloadAction<GroupType>) => {
      const groupType = action.payload
      const stateIndex = state.groupsByType.findIndex((x) => x.groupType === groupType)
      const initialStateIndex = initialState.groupsByType.findIndex(
        (x) => x.groupType === groupType
      )
      state.groupsByType[stateIndex] = { ...initialState.groupsByType[initialStateIndex] }
    },
    clearUsers: (state) => {
      state.participantData = initialState.participantData
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParticipantGroups.fulfilled, (state, action) => {
      const payload = action.payload as ParticipantGroupsSearch

      state.groupsByType
        .filter((x) => x.groupType === action.meta.arg.groupType)
        .forEach((x) => {
          if (payload.isSearch) {
            x.groups = payload.groups
          }

          if (!payload.isSearch) {
            x.groups = [...x.groups, ...payload.groups]
          }

          x.total = payload.total
          x.totalWithChildren = payload.totalWithChildren
          x.nextPage = payload.nextPage
          x.pageSize = payload.pageSize
          x.isLoading = false
        })
    })
    builder.addCase(getParticipantUsers.fulfilled, (state, action) => {
      const payload = action.payload as ParticipantUsersSearch

      if (payload.isSearch) {
        state.participantData.users = payload.users
      }

      if (!payload.isSearch) {
        state.participantData.users = [...state.participantData.users, ...payload.users]
      }

      state.participantData.total = payload.total
      state.participantData.nextPage = payload.nextPage
      state.participantData.pageSize = payload.pageSize
      state.participantData.isLoading = false
    })

    builder.addCase(getParticipantGroups.pending, (state, action) => {
      state.groupsByType
        .filter((x) => x.groupType === action.meta.arg.groupType)
        .forEach((x) => {
          x.isLoading = true
        })
    })
    builder.addCase(getParticipantUsers.pending, (state) => {
      state.participantData.isLoading = true
    })

    builder.addCase(getParticipantGroups.rejected, (state, action) => {
      state.groupsByType
        .filter((x) => x.groupType === action.meta.arg.groupType)
        .forEach((x) => {
          x.isLoading = false
          if (action.payload) {
            x.error = {
              message: action.payload.message,
            }
          }
        })
    })
    builder.addCase(getParticipantUsers.rejected, (state, action) => {
      state.participantData.isLoading = false
      if (action.payload) {
        state.participantData.error = {
          message: action.payload.message,
        }
      }
    })
  },
})

export const participantGroupsReducer = participantGroupsSlice.reducer
export const { clearGroups, clearUsers } = participantGroupsSlice.actions
