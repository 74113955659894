import React from 'react'
import styled from 'styled-components/macro'
import Avatar from 'modules/common/components/Avatar/index'
import { GroupOutlined, UserOutlined } from 'assets/svg'
import { SearchSection } from 'modules/create-mention/components'
import { MentionRecipient } from 'store/types'
import SearchButton from 'modules/common/components/SearchButton'

interface IProps {
  recipient?: MentionRecipient | null
  onClick: () => void
}

export const NOBODY_ID = 'NOBODY'

const UserInfo: React.FC<IProps> = ({ recipient, onClick }) => {
  return (
    <UserInfoWrapper>
      {recipient ? (
        <>
          {recipient.id !== NOBODY_ID && (
            <Avatar
              logo={recipient.logo}
              size={80}
              defaultLogo={recipient?.type === 'group' ? GroupOutlined : UserOutlined}
            />
          )}
          <SearchButton onClick={onClick} buttonText={recipient.name} />
        </>
      ) : (
        <SearchSection onClick={onClick} />
      )}
    </UserInfoWrapper>
  )
}

export default UserInfo

const UserInfoWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7px;
`
