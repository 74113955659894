import { TimelineMentionResponse } from 'api/timeline/types/response'
import { TimelineMention, TimelineMentionType } from 'store/types'
import { mapMedia } from './TimeLineMapper'

const COMPLIMENT_TYPE = 1
const COMPLAINT_TYPE = 0
const SUGGESTION_TYPE = 2
const NOTE_TYPE = 3
const COMMENT_TYPE = 4
const SUPPORT_TYPE = 5
const TOPIC_TYPE = 6

export const mapMentionType = (type: number) => {
  switch (type) {
    case COMPLAINT_TYPE:
      return TimelineMentionType.Complaint
    case COMPLIMENT_TYPE:
      return TimelineMentionType.Compliment
    case SUGGESTION_TYPE:
      return TimelineMentionType.Suggestion
    case NOTE_TYPE:
      return TimelineMentionType.Note
    case COMMENT_TYPE:
      return TimelineMentionType.Comment
    case SUPPORT_TYPE:
      return TimelineMentionType.Support
    case TOPIC_TYPE:
      return TimelineMentionType.Topic
    default:
      throw new Error('Please provide valid mention type')
  }
}

export const mentionMapper = (mention: TimelineMentionResponse): TimelineMention => ({
  id: mention.id,
  media: mapMedia(mention.attachment),
  title: mention.title,
  messages: mention.messages,
  mentionType: mapMentionType(mention.mentionType),
  isHandler: mention.isHandler,
  recipient: mention.recipient,
  sender: mention.sender,
  isAnon: mention.isAnonymous,
  lastMessage: mention.lastMessage,
})
