import {
  CommentItem,
  MentionChatResponse,
  MentionMessageResponse,
} from 'api/mentions/types/response'
import { MentionChat, ChatMessageType, TimelineMention } from 'store/types'
import { mapMentionType } from './TimelimeMentionMapper'
import { mapMedia } from './TimeLineMapper'

const DEFAULT_MESSAGE = 0
const SENDER_MESSAGE = 1
const ANSWERED_SENDER_MESSAGE = 2
const COMMENT_MESSAGE = 3

const mapMessageType = (type: number) => {
  switch (type) {
    case DEFAULT_MESSAGE:
      return ChatMessageType.Default
    case SENDER_MESSAGE:
      return ChatMessageType.SenderMessage
    case ANSWERED_SENDER_MESSAGE:
      return ChatMessageType.AnsweredSenderMessage
    case COMMENT_MESSAGE:
      return ChatMessageType.Comment
    default:
      return ChatMessageType.Default
  }
}

export const mapMentionChat = (chat: MentionChatResponse): MentionChat => ({
  ...chat,
  logo: chat.recipient != null ? chat.recipient.logo : null,
  fullName: chat.recipient != null ? chat.recipient.fullName : '',
  messages: chat.messages.map((message: MentionMessageResponse) => ({
    ...message,
    messageType: mapMessageType(message.messageType),
  })),
})

export const commentsMapper = (mention: CommentItem): TimelineMention => ({
  id: mention.id,
  media: mapMedia(mention.attachment),
  title: mention.title,
  messages: mention.messages,
  mentionType: mapMentionType(mention.mentionType),
  isHandler: mention.isHandler,
  recipient: mention.recipient,
  sender: mention.sender,
  isAnon: mention.isAnonymous,
  date: mention.date,
  lastMessage: mention.lastMessage,
})
