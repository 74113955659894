import { SerializedError } from '@reduxjs/toolkit'
import { AvailableAppSettingsResponse } from 'api/timeline/types/response'
import { EpisodeDetailsTimeline, TimelineItemInfo, TimelineItemType } from 'store/types'
import { PostCategoryItemType } from 'store/types/post'

export interface TimelineStateType {
  timelineItems: BaseTimeLineItem<any>[]
  availableAppSettings: AvailableAppSettingsResponse[]
  page: number
  expandedItemId: string
  expandLoadingId: string
  currentQuestionPrivacySettings: TimelineItemInfo
  currentResultPrivacySettings: TimelineItemInfo
  isLastPage: boolean
  details: EpisodeDetailsTimeline[]
  success: boolean
  error: SerializedError | null
  isLoading: boolean
  isInitialLoadPending: boolean
}

export interface BaseTimeLineItem<T> {
  id: string
  type: TimelineItemType
  data: T
}

export enum AppSettingsTypes {
  ThirdPartyApp = 1,
  Compliment = 2,
  Suggestion = 3,
  Complaint = 4,
  Post = 5,
  Search = 6,
  Session = 7,
  Calendar = 8,
  MyCourses = 9,
  NewGroup = 10,
  Topics = 11,
}

export interface ShowQuestionInfoType {
  id: string
  resultType: number
}

export interface AnswerQuestionType {
  questionId: string
  answer: any
  value?: number
}

export interface Error {
  message: string
}

export enum SessionWhereType {
  OnLocation = 0,
  Online = 1,
}

export interface TimelineSessionPostPlace {
  type: SessionWhereType
  text: string
}

export interface TimelineSessionPostAppointment {
  start: string
  end: string
}

export interface PostAnswerType {
  timelineItemId: string
  answer: {
    id: string
    link: string
    isEmbedded: boolean
    text: string
    type: PostCategoryItemType
    postUserId: string | null
    sessionPostAppointment: TimelineSessionPostAppointment
    sessionPostPlace: TimelineSessionPostPlace
    sessionPostAppointmentOld: TimelineSessionPostAppointment
    sessionPostPlaceOld: TimelineSessionPostPlace
  }
}

export interface PostAnswerRequestType extends PostAnswerType {
  postId: string
}
