import React from 'react'

export const ThreeDotSvg = ({
  color = '#FFFFFF',
  opacity = '1',
  className,
}: {
  color?: string
  opacity?: string
  className?: string
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="18"
    viewBox="0 0 4 18"
    opacity={opacity}
    style={{ transform: 'scale(1.2)' }}
  >
    <g transform="translate(443 -338) rotate(90)">
      <path
        d="M2,0A2,2,0,1,1,0,2,2,2,0,0,1,2,0Z"
        transform="translate(356 439) rotate(90)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,1,1,0,2,2,2,0,0,1,2,0Z"
        transform="translate(349 439) rotate(90)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,1,1,0,2,2,2,0,0,1,2,0Z"
        transform="translate(342 439) rotate(90)"
        fill={color}
      />
    </g>
  </svg>
)
