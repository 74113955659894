import { LanguagesType } from './enums'
import type { EpisodeItem, TextTypesType } from './episode'
import { AttachmentResponse, SubEpisodes, User } from './objects'
import { Media, VideoFromDeviceMedia, VideoMedia } from './timelineMedia'
import type { PostCategoryItemType, PostCategoryType } from './post'

export enum ServerTimeLineItemType {
  Question = 0,
  Mention = 1,
  Result = 2,
  SeriesResult = 3,
  GroupedMention = 4,
  Episode = 5,
  CourseResult = 6,
  Post = 7,
  GroupedSeriesVersionResult = 8,
  GroupedUserEpisode = 9,
}

export enum TimelineItemType {
  AnsweredQuestion,
  AnsweredSeries,
  Mention,
  Question,
  SeriesResult,
  Compressed,
  Episode,
  CourseResult,
  Post,
  GroupedSeriesResults = 11,
  GroupedEpisodes = 12,
}

export enum TimelineItemQuestionType {
  Multiple,
  Mood,
  LikeDislike,
  YesNo,
  Slider,
  Text = 7,
  Visual = 8,
  Attachment = 9,
  Open = 10,
  Tip = 11,
}

export enum LikeDislikeAnswerOption {
  Like,
  Dislike,
}

export enum SliderKey {
  First,
  Second,
  Third,
  Fourth,
  Fifth,
}

export enum MoodAnswerOptions {
  Worse,
  Bad,
  Common,
  Good,
  Best,
}

export enum YesNoAnswerOption {
  Yes,
  No,
}

export interface BaseTimeLineItem<T> {
  id: string
  type: TimelineItemType
  data: T
  groupParentId?: string
}

export interface TimelineAnswer {
  id: string
  key: string | LikeDislikeAnswerOption | MoodAnswerOptions | YesNoAnswerOption | SliderKey
  value: string | number
  isAnswerCorrect: boolean
  isAnswerSaved: boolean
}

export interface TimelineQuestion {
  id: string
  questionId: string
  date: string
  media: Media | VideoMedia | null
  text: string
  variants: TimelineAnswer[]
  answer: TimelineAnswer | null
  isSaved: boolean
  isSaveInProgress: boolean
  value: number | null
  questionType: TimelineItemQuestionType
  answered: boolean
  min?: string | null
  max?: string | null
  unreadCommentsCount?: number
  hasNote?: boolean
  properties?: any
  hasWrongAnswer?: boolean
  isCommentsFollowed?: boolean
  isSeriesQuestion?: boolean
  concerning?: number
  isHighlighted?: boolean
  itemType: TimelineItemQuestionType
}

export interface TimelineQuestionResults {
  id: string
  media: Media | VideoMedia | null
  text: string
  title: string
  variants: ResultOption[]
  questionType: TimelineItemQuestionType
  date: string
  min?: string
  max?: string
  userName: string | null
}

export interface ResultOption extends TimelineAnswer {
  percent: number
}

export enum MentionType {
  COMPLIMENT,
  TIP,
  COMPLAINT,
}

export interface NewMentionInfo {
  type: MentionType
}

export interface NewPositiveMentionInfo extends NewMentionInfo {
  timelineViewers: User[]
  accessViewers: User[]
}

export interface SeriesResultOption {
  characteristic: string
  percent: number
}

export interface SeriesResultType {
  media: Media | VideoMedia | VideoFromDeviceMedia | null
  header: string
  title: string
  points: number
  hasReport: boolean
  userSeriesVersionId: string
  id: string
  resultOptions: SeriesResultOption[]
  userName: string | null
  wasRead: boolean
}

export enum EpisodeProgressType {
  Inactive = 1,
  Invite = 2,
  Continue = 3,
  Restart = 4,
  Completed = 5,
}

export interface TimelineEpisode {
  id: string
  seriesName: string
  type: EpisodeProgressType
  episodeLogo?: string | null
  episodeName: string
  index: number
  textTypes: TextTypesType[]
  media: Media | VideoMedia | null
  progress: number
  unreadMessageCount: number
  userSeriesVersionId: string
  isCommentsFollowed?: boolean
  groupParentId: string | null
}

export interface ActivePagePropertiesType {
  pageHeader?: string
  isPreviousPage: boolean
  episodeSubtitles: string | null
  uid: string
  index: number
}

export interface ActivePageTimeline {
  attachment: Media | VideoMedia | VideoFromDeviceMedia | null
  seriesVersionAttachment: string | null
  isLastPage: boolean
  isActivePage: boolean
  previosPage: boolean
  properties: ActivePagePropertiesType
  questionId: string
  questionProgress: number
  questions: EpisodeItem[]
}

export interface EpisodeDetailsTimeline {
  id: string
  attachment: Media | VideoMedia | VideoFromDeviceMedia | null
  progress: number
  score: number
  episodeName: string
  properties: string
  textTypes: {
    id: number
    text: string | null
  }[]
  subEpisodes: SubEpisodes[]
  times: number
  type: EpisodeProgressType
  episodeLogo: string
  unreadMessageCount: number
  isCommentsFollowed: boolean
  hasQuestions: boolean
}

export interface CourseResult {
  media: Media | VideoMedia | null
  header: string
  title: string
  points: number
  hasReport: boolean
  id: string
  resultOptions: SeriesResultOption[]
  userName: string | null
  userSeriesVersionId: string
  groupParentId: string | null
  wasRead: boolean
}

export enum ResultType {
  QuestionResult,
  SeriesResult,
  AnsweredQuestion,
  CourseResult,
}

export interface PostPlaceType {
  type: number
  text: string
}

export interface PostAppointmentType {
  start: string
  end: string
}

export interface TimelinePostAnswer {
  id: string
  link: string
  isEmbedded: boolean
  text: string
  type: PostCategoryItemType
  postUserId: string
  sessionPostAppointment: PostAppointmentType
  sessionPostPlace: PostPlaceType
  sessionPostAppointmentOld: PostAppointmentType
  sessionPostPlaceOld: PostPlaceType
}

export interface TimelinePost {
  id: string
  postId: string
  dateActiveFrom: string
  categoryTitle: string
  categoryLogo: string
  categoryType: PostCategoryType
  postTitle: string
  postText: string
  media: Media | VideoMedia | null
  attachment: AttachmentResponse
  postItems: TimelinePostAnswer[]
  answer: TimelinePostAnswer
  unreadCommentsCount: number
  hasNote: boolean
  isRead: boolean
  isFollowed: boolean
  isHighlighted: boolean
  sessionActivityId: string
  journeyActivityId: string
  journeyId: string
  sessionPostAppointment: PostAppointmentType
  sessionPostAppointmentOld: PostAppointmentType
  sessionPostPlace: PostPlaceType
  sessionPostPlaceOld: PostPlaceType
}

export interface CategoryItemTranslates {
  id: string
  languageId: LanguagesType
  text: string
}

export interface PostItemTranslates {
  id: string
  postItemId: string
  languageId: LanguagesType
  text: string
}

export interface CategoryItem {
  id: string
  languageId: LanguagesType
  text: string
  order: number
  type: number
  link: string
  isEmbedded: boolean
  categoryId: string
  translates?: CategoryItemTranslates[]
}
