export enum AuthRoutes {
  PRIVACY_POLICY = '/auth/policy',
  EMAIL = '/auth/email',
  ACCESS_CODE = '/auth/access-code',
  PASSWORD = '/auth/create-password',
  MY_ACCOUNT = '/auth/account',
  THANK_YOU = '/auth/thank-you',
  PHONE = '/auth/phone',
  LANGUAGE = '/auth/language',
  CHECK_PASSWORD = '/auth/check-password',
  LOGO = '/auth/user-logo',
}

export enum AppRoutes {
  ORGANIZATIONS = '/app/login/organizations',
  FORGOT_PASSWORD = '/forgot-password',
  TIMELINE = '/app/timeline',
  ADD_COMPLIMENT = '/app/add-compliment/:appSettingId/:appTitle/:appTitleSingular',
  ADD_SUGGESTION = '/app/add-suggestion/:appSettingId/:appTitle/:appTitleSingular',
  ADD_COMPLAINT = '/app/add-complaint/:appSettingId/:appTitle/:appTitleSingular',
  ADD_TOPIC = '/app/add-topic/:appSettingId/:appTitle/:appTitleSingular',
  EDIT_TOPIC = '/app/edit-topic/:mentionId',
  LIST_TOPICS = '/app/topics/:appSettingId/:appTitle/:appTitleSingular',
  OPEN_EPISODE = '/app/open-episode/seriesVersion/:seriesVersionId/episode/:episodeId?/:subEpisodeId?',
  EPISODE_PAGE = '/app/episode-page/episode/:episodeId/episodeType/:episodeType',
  SUB_EPISODE = '/app/sub-episode/seriesVersion/:seriesVersionId/episode/:episodeId',
  ABOUT_QUESTION = '/app/about-question/question/:questionId',
  EPISODE_RESULT = '/app/episode-result/',
  EPISODE_PRIVACY = '/app/episode-privacy/id/:id/isGrouped/:isGrouped/type/:type',
  QUESTION_PRIVACY = '/app/question-privacy/id/:questionId/',
  RESULT_PRIVACY = '/app/result-privacy/id/:resultId/type/:resultType',
  ABOUT_ME = '/app/about-me/',
  PASSWORD_CHECK = '/app/confirm-password/',
  ORGANIZATION = '/app/organization/',
  ABOUT_OTHERS = '/app/about-others/id/:id/type/:type',
  ITEM_DETAILS = '/app/details/episode/:episodeId/episodeType/:episodeType/question/:questionId/isOthers/:isOthersScreen/fromSearch/:fromSearch',
  ADD_COMMENT = '/app/add-comment/episode/:episodeId/episodeType/:episodeType/question/:questionId/isOthers/:isOthersScreen',
  CHANGE_PASSWORD = '/app/change-password',
  MENTION_CHAT = '/app/mention-chat/:mentionId',
  COMPLAINT_PRIVACY = '/app/complaint-privacy/recipient/:recipientId/handler/:handlerId/isAnonymous/:isAnonymous/:appTitleSingular',
  COMPLAINT_CHAT_PRIVACY = '/app/complaint-chat-privacy/mention/:mentionId/handler/:handlerId/:appTitleSingular',
  MENTION_CHAT_PRIVACY = '/app/mention-chat-privacy/mention/:mentionId/type/:mentionType/:appTitleSingular',
  MENTION_PRIVACY = '/app/mention-privacy/recipient/:recipientId/type/:mentionType/:appTitleSingular',
  THANK_YOU = '/app/thank-you',
  SEARCH = '/app/search',
  MY_COURSES = '/app/my-courses/:screenTitle',
  CALENDAR = '/app/calendar/:screenTitle',
  POSTS = '/app/posts',
  POST_EDIT = '/app/posts/:id',
  ABOUT_OTHERS_USER_SEARCH = '/app/about-others-search',
  NEW_GROUPS = '/app/new-group/:appSettingId/:screenTitle',
  NEW_GROUP_REGISTRATION = '/app/registration-by-new-group/:token',
  CHECK_PASSWORD_CREDENTIAL_CHANGE = '/app/credential-passwod-check',
  NEW_GROUP_REGISTRATION_LINK = '/app/new-group-registration-link/:groupType/:route/:token/:organizationLogo?/:screenTitle?',
}
type TArgs =
  | { path: AppRoutes.CHECK_PASSWORD_CREDENTIAL_CHANGE }
  | { path: AppRoutes.ORGANIZATIONS }
  | { path: AppRoutes.FORGOT_PASSWORD }
  | { path: AppRoutes.TIMELINE }
  | {
      path: AppRoutes.ADD_COMPLIMENT
      params: {
        appSettingId: string
        appTitle: string
        appTitleSingular: string
      }
    }
  | {
      path: AppRoutes.ADD_SUGGESTION
      params: {
        appSettingId: string
        appTitle: string
        appTitleSingular: string
      }
    }
  | {
      path: AppRoutes.ADD_COMPLAINT
      params: {
        appSettingId: string
        appTitle: string
        appTitleSingular: string
      }
    }
  | { path: AppRoutes.EPISODE_RESULT }
  | { path: AppRoutes.ABOUT_ME }
  | { path: AppRoutes.ORGANIZATION }
  | { path: AppRoutes.THANK_YOU }
  | { path: AppRoutes.ABOUT_OTHERS_USER_SEARCH }
  | { path: AppRoutes.SEARCH }
  | {
      path: AppRoutes.MY_COURSES
      params: { screenTitle: string }
    }
  | {
      path: AppRoutes.CALENDAR
      params: { screenTitle: string }
    }
  | { path: AppRoutes.POSTS }
  | { path: AppRoutes.POST_EDIT; params: { id: string } }
  | { path: AppRoutes.ABOUT_OTHERS; params: { id: string; type: string } }
  | {
      path: AppRoutes.COMPLAINT_CHAT_PRIVACY
      params: { mentionId: string; handlerId: string; appTitleSingular: string }
    }
  | {
      path: AppRoutes.MENTION_CHAT_PRIVACY
      params: { mentionId: string; mentionType: string; appTitleSingular: string }
      state?: { from?: string }
    }
  | {
      path: AppRoutes.COMPLAINT_PRIVACY
      params: {
        recipientId: string
        handlerId: string
        isAnonymous: string
        appTitleSingular: string
      }
    }
  | {
      path: AppRoutes.MENTION_PRIVACY
      params: {
        recipientId: string
        mentionType: string
        appTitleSingular: string
      }
    }
  | {
      path: AppRoutes.EPISODE_PRIVACY
      params: { id: string; isGrouped: string; type: string }
    }
  | {
      path: AppRoutes.QUESTION_PRIVACY
      params: { questionId: string }
    }
  | {
      path: AppRoutes.RESULT_PRIVACY
      params: { resultId: string; resultType: string }
    }
  | {
      path: AppRoutes.OPEN_EPISODE
      params: { seriesVersionId: string; episodeId: string; subEpisodeId?: string }
      state?: { other?: boolean }
    }
  | {
      path: AppRoutes.EPISODE_PAGE
      params: { episodeId: string; episodeType: string }
      state?: { viewMode?: boolean; other?: boolean; pageId?: string }
    }
  | { path: AppRoutes.SUB_EPISODE; params: { seriesVersionId: string; episodeId: string } }
  | { path: AppRoutes.ABOUT_QUESTION; params: { questionId: string } }
  | {
      path: AppRoutes.ITEM_DETAILS
      params: {
        questionId: string
        episodeType: string
        episodeId: string
        isOthersScreen?: string
        fromSearch?: string
      }
      state?: { pageIndex?: number; pageId?: string; from?: string }
    }
  | {
      path: AppRoutes.ADD_COMMENT
      params: {
        questionId: string
        episodeType: string
        episodeId: string
        isOthersScreen?: string
      }
    }
  | {
      path: AppRoutes.MENTION_CHAT
      params: {
        mentionId: string
      }
      state?: { from: string }
    }
  | {
      path: AppRoutes.NEW_GROUPS
      params: {
        appSettingId: string
        screenTitle: string
      }
    }
  | {
      path: AppRoutes.LIST_TOPICS
      params: {
        appSettingId: string
        appTitle: string
        appTitleSingular: string
      }
    }
  | {
      path: AppRoutes.ADD_TOPIC
      params: {
        appSettingId: string
        appTitle: string
        appTitleSingular: string
      }
      state?: { from?: string }
    }
  | {
      path: AppRoutes.EDIT_TOPIC
      params: {
        mentionId: string
      }
      state?: { from?: string }
    }
  | {
      path: AppRoutes.NEW_GROUP_REGISTRATION
      params: {
        token: string
      }
    }
  | {
      path: AppRoutes.NEW_GROUP_REGISTRATION_LINK
      params: {
        groupType: string
        route: string
        token: string
        organizationLogo: string | undefined
        screenTitle: string | undefined
      }
    }

type TArgsWithParams = Extract<TArgs, { path: any; params: any }>

export const createPath = (args: TArgs) => {
  /*eslint-disable-next-line no-prototype-builtins*/
  if (args.hasOwnProperty('params') === false) return args.path

  return Object.entries((args as TArgsWithParams).params).reduce(
    (previousValue: string, [param, value]) => {
      return previousValue.replace(`:${param}`, `${value}`)
    },
    args.path
  )
}
