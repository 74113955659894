import { authInstanceOrg } from 'api/axios.config'
import { mapGroupedEpisodes, mapGroupedResults, mapTimeLineItems } from 'api/mappers'
import { ServerTimeLineItemType, TimelineItemInfo } from 'store/types'
import {
  AnswerPostResponse,
  AnswerQuestionResponse,
  AvailableAppSettingsResponse,
  CourseResultResponse,
  TimeLineItemResponse,
  TimelineResponse,
} from './types/response'
import axios from 'axios'
import { getCurrentUser } from 'modules/common/helpers'
import { getFromLocalStorage } from 'utils/storage'
import { LOCAL_STORAGE_KEYS } from 'modules/common/constants/storageKeys'
import { EpisodeResponse, EpisodePrivacySettingsResponse } from 'api/episode/types/response'

export class TimelineApi {
  static getTimelineRequest = async (page: number) => {
    const { data } = await authInstanceOrg.get<TimelineResponse>(
      `/timeLine/getTimeLine?daysCount=${page}`
    )
    const a = mapTimeLineItems(data.data)
    return {
      data: a,
      isLastPage: data.isLastPage,
    }
  }

  static getTimelineItemRequest = async (entityId: string, type: ServerTimeLineItemType) => {
    const { data } = await authInstanceOrg.get<TimeLineItemResponse>(
      `/timeLine/getTimeLineItem/${entityId}/${type}`
    )

    if (data) {
      return mapTimeLineItems([data])[0]
    }
    return null
  }

  static updateLastSeenTimelineRequest = async () => {
    return await authInstanceOrg.put<void>('/user/updateLastSeenTimeLine', {})
  }

  static questionPrivacySettingsRequest = async (id: string) => {
    return await authInstanceOrg.get<TimelineItemInfo>(`/question/getQuestionInfo/${id}`)
  }
  static resultPrivacySettingsRequest = async (id: string, resultType: number) => {
    return await authInstanceOrg.get<TimelineItemInfo>(
      `/question/getResultInfo/${id}/${resultType}`
    )
  }
  static postQuestionAnswerRequest = async (
    questionId: string,
    answerId: string,
    value?: number
  ) => {
    return await authInstanceOrg.post<AnswerQuestionResponse>(`timeLine/sendQuestionAnswer`, {
      questionId,
      answerId,
      value,
    })
  }

  static highlightQuestionRequest = async (
    userEpisodeId: string,
    questionId: string,
    isHighlighted: boolean
  ) => {
    return await authInstanceOrg.post<AnswerQuestionResponse>(`timeLine/highlightQuestion`, {
      userEpisodeId,
      questionId,
      isHighlighted,
    })
  }

  static getCertificateRequest = async (seriesVersionId: string) => {
    const {
      accessToken: { token },
    } = getCurrentUser()

    const url = getFromLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL)

    const response = await axios.get(`${url}seriesVersion/getCertificate/${seriesVersionId}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
        Authorization: `Bearer ${token}`,
      },
    })

    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    const pdfWindow = window.open()
    if (pdfWindow) pdfWindow.location.href = fileURL
    //const content = response.headers['content-type']
    //download(response.data, 'certificate.pdf', content)
  }

  static getAvailableAppSettingsRequest = async () => {
    const { data } = await authInstanceOrg.get<AvailableAppSettingsResponse[]>(
      `/appSetting/getAvailableAppSettings`
    )

    return data
  }

  static hightLightPostRequest = async (id: string) => {
    await authInstanceOrg.post<void>(`/post/highlight/${id}`)
  }

  static readPostRequest = async (id: string) => {
    await authInstanceOrg.post<void>(`/post/read/${id}`)
  }

  static answerPostRequest = async (id: string, postUserId: string) => {
    const { data } = await authInstanceOrg.post<AnswerPostResponse>(
      `post/answer/${id}/${postUserId}`,
      {}
    )

    return data
  }

  static getGroupedResultsRequest = async (groupedResultsId: string) => {
    const { data } = await authInstanceOrg.get<CourseResultResponse[]>(
      `timeLine/getGroupedResults/${groupedResultsId}`
    )

    return mapGroupedResults(data, groupedResultsId)
  }

  static getGroupedEpisodesRequest = async (groupedEpisodesId: string) => {
    const { data } = await authInstanceOrg.get<EpisodeResponse[]>(
      `timeLine/getGroupedEpisodeInvites/${groupedEpisodesId}`
    )

    return mapGroupedEpisodes(data, groupedEpisodesId)
  }

  static groupedItemPrivacySettingsRequest = async (userSeriesVersionId: string, type: number) => {
    return authInstanceOrg.get<EpisodePrivacySettingsResponse>(
      `seriesVersion/getGroupedItemPrivacySettings/${userSeriesVersionId}/${type}`
    )
  }

  static readRequest = async (temeLineItemId: string) => {
    await authInstanceOrg.post<void>(`timeLine/read/${temeLineItemId}`)
  }
}
