import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { MentionsApi } from 'api/mentions'
import { Error } from './types'
import { MentionAttachment, MentionChat, MentionChatMessage } from 'store/types'
import { deleteMessageById, toggleFollowMention, toggleIsHandled } from './slice'

export const getMentionChat = createAsyncThunk<MentionChat, string, { rejectValue: Error }>(
  'mention-chat/getMentionChat',
  async (mentionId, { rejectWithValue }) => {
    try {
      return await MentionsApi.getChatRequest(mentionId)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
export const sendMentionChatMessage = createAsyncThunk<
  MentionChatMessage,
  { mentionId: string; newMessageText: string; attachment: MentionAttachment | undefined },
  { rejectValue: Error }
>(
  'mention-chat/sendMentionChatMessage',
  async ({ mentionId, newMessageText, attachment }, { rejectWithValue }) => {
    try {
      return await MentionsApi.sendMessageRequest(mentionId, newMessageText, attachment)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const updateLastSeen = createAsyncThunk<void, string, { rejectValue: Error }>(
  'mention-chat/updateLastSeen',
  async (chatId, { rejectWithValue }) => {
    try {
      await MentionsApi.updateLastSeenRequest(chatId)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const notifyComplaintIsHandled = createAsyncThunk<void, string, { rejectValue: Error }>(
  'mention-chat/notifyComplaintIsHandled',
  async (mentionId, { rejectWithValue, dispatch }) => {
    try {
      await MentionsApi.complaintIsHandledRequest(mentionId)
      setTimeout(() => {
        dispatch(toggleIsHandled())
      }, 600)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const followMention = createAsyncThunk<void, string, { rejectValue: Error }>(
  'mention-chat/followMention',
  async (mentionId, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleFollowMention())
      await MentionsApi.followMentionRequest(mentionId)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const completeSatisfactionSurvey = createAsyncThunk<
  void,
  { messageId: string; answer: string },
  { rejectValue: Error }
>('mention-chat/completeSatisfactionSurvey', async ({ messageId, answer }, { rejectWithValue }) => {
  try {
    await MentionsApi.completeComplaintSatisfactionSurveyRequest(messageId, answer)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const reverseComplaintIsHandled = createAsyncThunk<void, string, { rejectValue: Error }>(
  'mention-chat/reverseComplaintIsHandled',
  async (mentionId, { rejectWithValue }) => {
    try {
      await MentionsApi.reverseComplaintIsHandledRequest(mentionId)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const deleteMessage = createAsyncThunk<void, string, { rejectValue: Error }>(
  'mention-chat/reverseComplaintIsHandled',
  async (messageId, { rejectWithValue, dispatch }) => {
    try {
      dispatch(deleteMessageById(messageId))
      await MentionsApi.deleteMessageRequest(messageId)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const deleteMention = createAsyncThunk<void, string, { rejectValue: Error }>(
  'mention-chat/deleteMention',
  async (mentionId, { rejectWithValue }) => {
    try {
      await MentionsApi.deleteMention(mentionId)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
