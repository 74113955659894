import React from 'react'
import styled, { ITheme } from 'styled-components/macro'
import { useBrandingColors } from 'hooks/useBrandingColors'
import UnreadMessagesBadge from 'modules/common/components/UnreadMessagesBadge'
import { TimelineMentionType } from 'store/types'
import { useSelector } from 'react-redux'
import { selectAvailableAppSettings } from 'store/slices/timeline/selectors'
import { AppSettingsTypes } from 'store/slices/timeline/types'
import { getScaledValue } from 'utils/scale'
import Avatar from 'modules/common/components/Avatar'
import { PersonOutlinedSmall } from 'assets/svg'

interface IProps {
  children: React.ReactNode
  iconSender: any
  iconRecipient?: any
  hasRecipient?: boolean
  unreadMessagesCount?: number
  mentionType?: number
  customMargin?: number
  isGrouped?: boolean
  isRecipientGroup: boolean
}

const TimelineMentionLayout: React.FC<IProps> = ({
  children,
  iconSender,
  iconRecipient,
  hasRecipient,
  unreadMessagesCount,
  mentionType,
  customMargin,
  isGrouped = false,
  isRecipientGroup,
}) => {
  const {
    textOnBackground,
    frame,
    suggestionStart,
    suggestionEnd,
    complaintStart,
    complaintEnd,
    complimentStart,
    complimentEnd,
  } = useBrandingColors()
  const appSettings = useSelector(selectAvailableAppSettings)

  const getStartColor = (type: number) => {
    switch (type) {
      case TimelineMentionType.Complaint:
        return (
          appSettings.find((item) => item.type === AppSettingsTypes.Complaint)?.backgroundStart ||
          complaintStart
        )

      case TimelineMentionType.Compliment:
        return (
          appSettings.find((item) => item.type === AppSettingsTypes.Compliment)?.backgroundStart ||
          complimentStart
        )

      case TimelineMentionType.Suggestion:
        return (
          appSettings.find((item) => item.type === AppSettingsTypes.Suggestion)?.backgroundStart ||
          suggestionStart
        )
      case TimelineMentionType.Topic:
        return (
          appSettings.find((item) => item.type === AppSettingsTypes.Topics)?.backgroundStart ||
          suggestionStart
        )
      default:
        return suggestionStart
    }
  }
  const getEndColor = (type: number) => {
    switch (type) {
      case TimelineMentionType.Complaint:
        return (
          appSettings.find((item) => item.type === AppSettingsTypes.Complaint)?.backgroundEnd ||
          complaintEnd
        )

      case TimelineMentionType.Compliment:
        return (
          appSettings.find((item) => item.type === AppSettingsTypes.Compliment)?.backgroundEnd ||
          complimentEnd
        )

      case TimelineMentionType.Suggestion:
        return (
          appSettings.find((item) => item.type === AppSettingsTypes.Suggestion)?.backgroundEnd ||
          suggestionEnd
        )
      case TimelineMentionType.Topic:
        return (
          appSettings.find((item) => item.type === AppSettingsTypes.Topics)?.backgroundEnd ||
          suggestionEnd
        )
      default:
        return suggestionEnd
    }
  }

  const renderIcons = () => {
    if (isRecipientGroup) {
      return (
        <IconSender size={56} logo={iconRecipient} defaultLogo={PersonOutlinedSmall}></IconSender>
      )
    }

    return (
      <>
        <IconSender size={56} logo={iconSender} defaultLogo={PersonOutlinedSmall}></IconSender>
        {hasRecipient && (
          <IconRecipient
            size={56}
            logo={iconRecipient}
            defaultLogo={PersonOutlinedSmall}
          ></IconRecipient>
        )}
      </>
    )
  }

  return (
    <ItemWrapper isGrouped={isGrouped} borderColor={frame} customMargin={customMargin}>
      <IconWrapper isGrouped={isGrouped}>{renderIcons()}</IconWrapper>

      {isGrouped && <ExtraSpace></ExtraSpace>}

      {unreadMessagesCount && unreadMessagesCount > 0 ? (
        <UnreadMessagesWrapper>
          <UnreadMessages
            unreadMessagesCount={unreadMessagesCount}
            startColor={mentionType ? getStartColor(mentionType) : complimentStart}
            endColor={mentionType ? getEndColor(mentionType) : complimentEnd}
            textColor={textOnBackground}
          />
        </UnreadMessagesWrapper>
      ) : null}

      {children}
    </ItemWrapper>
  )
}

export default TimelineMentionLayout

const UnreadMessages = styled(UnreadMessagesBadge)`
  right: -3px;
`

const IconWrapper = styled.div<{ isGrouped: boolean }>`
  position: absolute;
  display: flex;
  top: ${({ isGrouped }) => (isGrouped ? '9px' : '-28px')};
  width: 100%;
  left: 0px;
  justify-content: center;
`

const ExtraSpace = styled.div`
  height: 40px;
`

export const ItemWrapper = styled.div<{
  borderColor: string
  customMargin?: number
  isGrouped: boolean
}>`
  ${({ isGrouped }) => (isGrouped ? 'background: #FFFFFF' : null)};
  padding: ${getScaledValue(7, 'px')};
  border: 1px ${({ borderColor }) => borderColor} solid;
  border-radius: 16px;
  box-shadow: 4px 3px 6px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: ${({ customMargin }) =>
    customMargin !== undefined ? `${customMargin}px` : '60px'};
  position: relative;
`

const IconSender = styled(Avatar)`
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    width: 42px;
    height: 42px;
  }
`
const IconRecipient = styled(Avatar)`
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    width: 42px;
    height: 42px;
  }
  margin-left: 20px;
`

const UnreadMessagesWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: 25px;
`
