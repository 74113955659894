import axios, { AxiosInstance } from 'axios'
import { MASTER_API_URL } from './constants'
import { getCurrentUser } from 'modules/common/helpers'
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from 'utils/storage'
import { LOCAL_STORAGE_KEYS } from 'modules/common/constants/storageKeys'
import { TokenResponse } from './auth/types/response'
import { store } from 'store'
import { logout } from 'store/slices/auth/thunks'
import dayjs, { extend } from 'dayjs'
import dayjsPluginUTC from 'dayjs/plugin/utc'

extend(dayjsPluginUTC)

export const REFRESH_TOKEN_RESPONSE_TYPE = {
  SUCCESS: 0,
  FAILED: 1,
  USER_DOES_NOT_EXIST: 2,
  USER_DEACTIVATED: 3,
}

const orgConfig = {
  baseURL: getFromLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL),
}

const masterConfig = {
  baseURL: MASTER_API_URL,
}

const messagingConfig = {
  baseURL: getFromLocalStorage(LOCAL_STORAGE_KEYS.MESSAGING_URL),
}

let refreshTokenPromise = null as any

export const authInstanceOrg: AxiosInstance = axios.create(orgConfig)
export const unauthInstanceOrg: AxiosInstance = axios.create(orgConfig)

export const authInstanceMessaging: AxiosInstance = axios.create(messagingConfig)
export const unauthInstanceMessaging: AxiosInstance = axios.create(messagingConfig)

export const authInstanceMaster: AxiosInstance = axios.create(masterConfig)
export const unauthInstanceMaster: AxiosInstance = axios.create(masterConfig)

const saveToken = (
  access_token: {
    token: string
    expiresIn: number
  },
  refresh_token: string
) => {
  saveToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, access_token)
  saveToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refresh_token)
}

const destroyToken = () => {
  removeFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN)
  removeFromLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
}

const refreshToken = () => {
  const { credentialsInfo } = getCurrentUser()
  const { dispatch } = store

  return new Promise((resolve, reject) => {
    authInstanceOrg
      .post<{
        responseType: number
        userCredentials: TokenResponse
      }>(`/authorization/refreshtokenMobile`, {
        accessToken: getFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN),
        refreshToken: getFromLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN),
        credentialsInfo: {
          organizationId: credentialsInfo.organizationId,
          identifier: credentialsInfo.identifier,
        },
      })
      .then((response) => {
        const {
          data: { responseType, userCredentials },
        } = response

        if (responseType === REFRESH_TOKEN_RESPONSE_TYPE.SUCCESS) {
          saveToken(userCredentials.accessToken, userCredentials.refreshToken)
          return resolve(userCredentials.accessToken)
        }

        if (responseType !== REFRESH_TOKEN_RESPONSE_TYPE.SUCCESS) {
          throw 'Refresh token failed'
        }
      })
      .catch((error) => {
        destroyToken()
        dispatch(logout())
        return reject(error)
      })
  })
}

authInstanceOrg.interceptors.response.use(
  (res) => res,
  (error) => {
    const originalRequest = error.config
    const { dispatch } = store

    if (error.config && error.response && error.response.status === 401) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshToken()
          .then(() => {
            refreshTokenPromise = null
            authInstanceOrg(originalRequest)
          })
          .catch(() => {
            refreshTokenPromise = null
            destroyToken()
            dispatch(logout())
          })
      }
    }

    return Promise.reject(error)
  }
)

authInstanceOrg.interceptors.request.use((config) => {
  const access_token = getFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN)
  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token.token}`
    config.headers['X-Timezone-Offset'] = dayjs().utcOffset()
    return config
  }
  return config
})

authInstanceMessaging.interceptors.response.use(
  (res) => res,
  (error) => {
    const originalRequest = error.config
    const { dispatch } = store

    if (error.config && error.response && error.response.status === 401) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshToken()
          .then(() => {
            refreshTokenPromise = null
            authInstanceOrg(originalRequest)
          })
          .catch(() => {
            refreshTokenPromise = null
            destroyToken()
            dispatch(logout())
          })
      }
    }

    return Promise.reject(error)
  }
)

authInstanceMessaging.interceptors.request.use((config) => {
  const access_token = getFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN)
  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token.token}`
    config.headers['X-Timezone-Offset'] = dayjs().utcOffset()
    return config
  }
  return config
})

authInstanceMaster.interceptors.response.use(
  (res) => res,
  (error) => {
    const originalRequest = error.config

    if (error.config && error.response && error.response.status === 401) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshToken().then(() => {
          refreshTokenPromise = null
          authInstanceMaster(originalRequest)
        })
      }
    }

    return Promise.reject(error)
  }
)

authInstanceMaster.interceptors.request.use((config) => {
  const access_token = getFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN)
  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token.token}`
    config.headers['X-Timezone-Offset'] = dayjs().utcOffset()
    return config
  }
  return config
})
