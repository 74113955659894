import { mapMedia } from './TimeLineMapper'
import { mapMentionType } from './TimelimeMentionMapper'
import { TimelineItemType } from 'store/types'
import { GroupMentionResponse, TimelineMentionResponse } from 'api/timeline/types/response'

const mapMentions = (
  mentions: TimelineMentionResponse[],
  compressedMention: GroupMentionResponse,
  id: string
) => {
  return {
    type: TimelineItemType.Compressed,
    id,
    data: {
      appName: compressedMention.appName,
      groupName: compressedMention.groupName,
      logo: compressedMention.logo,
      unreadMessageCount: compressedMention.unreadMessageCount,
      mentionType: compressedMention.mentionType,
      mentions: mentions.map((item) => ({
        id: item.id,
        lastMessage: item.lastMessage,
        withinGroup: item.recipient !== null,
        media: mapMedia(item.attachment),
        title: item.title,
        messages: item.messages,
        mentionType: mapMentionType(item.mentionType),
        isHandler: item.isHandler,
        isAnon: item.isAnonymous,
        recipient: item.recipient
          ? item.recipient
          : {
              id: item.id,
              logo: compressedMention.logo,
              fullName: compressedMention.groupName,
              position: null,
              phoneNumber: '',
              isGroup: true,
            },
        sender: item.sender,
      })),
    },
  }
}

export const compressedMentionMapper = (compressedMention: GroupMentionResponse, id: string) => {
  return mapMentions(compressedMention.mentions, compressedMention, id)
  // const compliments = compressedMention.mentions.filter(
  //   (mention) => mention.mentionType === COMPLIMENT_TYPE
  // )
  // const complaints = compressedMention.mentions.filter(
  //   (mention) => mention.mentionType === COMPLAINT_TYPE
  // )
  // const tips = compressedMention.mentions.filter((mention) => mention.mentionType === TIP_TYPE)
  // const topics = compressedMention.mentions.filter(
  //   (mention) => mention.mentionType === TimelineMentionType.Topic
  // )

  // const compressedMentions = [] as any

  // const comressedCompliments = mapMentions(compliments, compressedMention)
  // const comressedComplaints = mapMentions(complaints, compressedMention)
  // const comressedTips = mapMentions(tips, compressedMention)
  // const comressedTopics = mapMentions(topics, compressedMention)

  // if (comressedCompliments) {
  //   compressedMentions.push(comressedCompliments)
  // }
  // if (comressedTips) {
  //   compressedMentions.push(comressedTips)
  // }
  // if (comressedComplaints) {
  //   compressedMentions.push(comressedComplaints)
  // }
  // if (comressedTopics) {
  //   compressedMentions.push(comressedTopics)
  // }
  // return compressedMentions
}
