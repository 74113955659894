import { mapMedia } from './TimeLineMapper'
import { mapMentionType } from './TimelimeMentionMapper'
import { TimelineMention } from 'store/types'
import { MentionInfoResponse } from 'api/user/types/response'

const metionIsValid = (mention: MentionInfoResponse) => {
  if (mention.recipient !== null && mention.sender !== null) {
    return true
  }
  return false
}

export const mapInfoMention = (mentions: MentionInfoResponse[]): TimelineMention[] => {
  return mentions.filter(metionIsValid).map((mention) => ({
    id: mention.id,
    media: mapMedia(mention.attachment),
    title: mention.title,
    messages: mention.messages,
    mentionType: mapMentionType(mention.mentionType),
    isHandler: mention.isHandler,
    recipient: mention.recipient,
    sender: mention.sender,
    date: mention.date,
    isAnon: mention.isAnonymous,
    lastUpdated: mention.lastUpdatedDate,
    lastMessage: mention.lastMessage,
  }))
}
