import React from 'react'
import styled, { ITheme } from 'styled-components/macro'
import { useBrandingColors } from 'hooks/useBrandingColors'
import { Button } from 'antd'
import { ThreeDotSvg } from 'assets/svg/ThreeDotSvg'
import { TextAvenirNextRegular } from 'modules/common/components/Typography'
import { getScaledValue } from 'utils/scale'

export interface MenuItems {
  text: string
  onClick: () => void
  icon?: React.ReactNode
}

interface ThreeDotMenuProps {
  title: string
  expanded: boolean
  setExpanded: () => void
  items: MenuItems[]
}

const ThreeDotMenu = ({ title, items, expanded, setExpanded }: ThreeDotMenuProps) => {
  const { text, textOnBackground } = useBrandingColors()

  const handleClick = (item: MenuItems) => {
    item.onClick()
  }

  return (
    <Container
      $textOnBackground={textOnBackground}
      $text={text}
      $expanded={expanded}
      onClick={setExpanded}
    >
      <ThreeDotRow>
        {expanded && <Title>{title}</Title>}
        <ButtonStyled
          onClick={(e) => {
            e.stopPropagation()
            setExpanded()
          }}
        >
          <ThreeDotSvg color={textOnBackground}></ThreeDotSvg>
        </ButtonStyled>
      </ThreeDotRow>
      {expanded &&
        items.map((item, index) => (
          <ItemRow
            key={index}
            onClick={() => {
              handleClick(item)
            }}
          >
            {item.icon}
            <ItemText>{item.text}</ItemText>
          </ItemRow>
        ))}
    </Container>
  )
}

export default ThreeDotMenu

const Title = styled.span`
  margin: 0 auto;
  font-size: ${getScaledValue(11, 'px')};
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 11px;
  }
`

const ItemText = styled.span`
  padding-left: 10px;
`

const Container = styled.div<{ $textOnBackground: string; $text: string; $expanded: boolean }>`
  position: absolute;
  right: 5px;
  top: 0px;
  z-index: 20;
  min-width: 320px;
  max-width: 500px;
  padding-bottom: 13px;
  padding-right: 10px;
  padding-top: 30px;
  border-bottom-left-radius: 20px;
  color: rgb(255 255 255);
  background: ${({ $expanded }) => ($expanded ? 'rgb(255 255 255 / 20%)' : 'transparent')};
`

const ThreeDotRow = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 10px;
`

const ItemRow = styled(TextAvenirNextRegular)`
  color: inherit;
  text-align: right;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: ${getScaledValue(20, 'px')};
  text-transform: none;
  margin-bottom: 20px;
  margin-left: 33px;
  line-height: 1;
  cursor: pointer;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 17px;
  }
`

const ButtonStyled = styled(Button)`
  &.ant-btn {
    padding: 0;
    border: none;
    background: transparent;
    color: inherit;
    border-radius: 10px;
    --antd-wave-shadow-color: none;
    height: auto;
    line-height: 0;
    width: 20px;
    height: 30px;

    &:hover,
    &:focus,
    &:active {
      border: none;
    }
  }
`
