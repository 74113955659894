import React from 'react'
import styled from 'styled-components/macro'
import { getScaledValue } from 'utils/scale'
import { TextAvenirNextRegular } from '../Typography'
import { NotesIcon } from 'assets/svg'
import { ITheme } from 'styles/types'

interface IProps {
  unreadMessagesCount: number
  startColor: string
  endColor: string
  textColor: string
  hasNote?: boolean
  className?: string
}

const UnreadMessagesBadge: React.FC<IProps> = ({
  unreadMessagesCount,
  startColor,
  endColor,
  textColor,
  hasNote,
  className,
}) => {
  return (
    <Background startColor={startColor} stopColor={endColor} className={className}>
      {hasNote ? (
        <NotesIcon noBackground isActive={false} />
      ) : (
        <UnreadMessagesCount textColor={textColor}>{unreadMessagesCount}</UnreadMessagesCount>
      )}
    </Background>
  )
}

export default UnreadMessagesBadge

const Background = styled.div<{ startColor: string; stopColor: string }>`
  width: ${getScaledValue(21, 'px')};
  height: ${getScaledValue(21, 'px')};
  background: ${({ startColor, stopColor }) => `linear-gradient(${startColor}, ${stopColor})`};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -14px;
  right: 25px;
  & > img {
    width: 16px;
  }

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    width: 21px;
    height: 21px;
    top: -8px;
  }
`

const UnreadMessagesCount = styled(TextAvenirNextRegular)<{ textColor: string }>`
  font-size: ${getScaledValue(14, 'px')};
  color: ${({ textColor }) => textColor};
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 14px;
  }
`
