import {
  MentionLastMessageUpdate,
  ModelState,
  TimelineAnswer,
  TimelineCompressedMentions,
  TimelineItemInfo,
  TimelineItemType,
  TimelineMention,
} from 'store/types'
import {
  createSlice,
  isFulfilled,
  isRejectedWithValue,
  isPending,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  getTimelineItems,
  loadMoreTimelineItems,
  getQuestionPrivacySettings,
  getResultPrivacySettings,
  getAvailableAppSettings,
  toggleHighlightPostItem,
  readPost,
  answerPost,
  getGroupedResults,
  getGroupedEpisodes,
  getNewTimelineItem,
  getUpdatedTimelineItem,
} from './thunks'
import { BaseTimeLineItem, TimelineStateType } from './types'

const sortTimelineItems = (a: BaseTimeLineItem<any>, b: BaseTimeLineItem<any>) => {
  const firstDate =
    // eslint-disable-next-line no-nested-ternary
    a.type === TimelineItemType.Mention
      ? new Date(
          (a.data as TimelineMention)?.lastMessage?.createdDate ||
            (a.data as TimelineMention)?.date ||
            0
        ).getTime()
      : a.type === TimelineItemType.Compressed
      ? Math.max(
          ...(a.data as TimelineCompressedMentions).mentions.map((x) =>
            new Date(x.lastMessage?.createdDate || x.date || 0).getTime()
          )
        )
      : new Date(a.data.date).getTime()
  const secondDate =
    // eslint-disable-next-line no-nested-ternary
    b.type === TimelineItemType.Mention
      ? new Date(
          (b.data as TimelineMention)?.lastMessage?.createdDate ||
            (b.data as TimelineMention)?.date ||
            0
        ).getTime()
      : b.type === TimelineItemType.Compressed
      ? Math.max(
          ...(b.data as TimelineCompressedMentions).mentions.map((x) =>
            new Date(x.lastMessage?.createdDate || x.date || 0).getTime()
          )
        )
      : new Date(a.data.date).getTime()

  return secondDate - firstDate
}

const sortMentions = (a: TimelineMention, b: TimelineMention) => {
  const firstDate = new Date(a.lastMessage?.createdDate || a.date || 0).getTime()
  const secondDate = new Date(b.lastMessage?.createdDate || b.date || 0).getTime()

  return secondDate - firstDate
}

const initialState: TimelineStateType = {
  timelineItems: [],
  availableAppSettings: [],
  expandedItemId: '',
  expandLoadingId: '',
  page: 0,
  currentQuestionPrivacySettings: {} as TimelineItemInfo,
  currentResultPrivacySettings: {} as TimelineItemInfo,
  isLastPage: false,
  details: [],
  isInitialLoadPending: false,
  isLoading: false,
  error: null,
  success: false,
}

const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setIsAnswered: (state: TimelineStateType, action: PayloadAction<string>) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.data.id === action.payload) {
          return {
            ...item,
            data: {
              ...item.data,
              answered: true,
            },
          }
        }
        return item
      })
    },

    setIsSaved: (state: TimelineStateType, action: PayloadAction<string>) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.data.id === action.payload) {
          return {
            ...item,
            data: {
              ...item.data,
              isSaved: true,
            },
          }
        }
        return item
      })
    },

    setQuestionAnswer: (
      state,
      action: PayloadAction<{ answer: TimelineAnswer | null; questionId: string }>
    ) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.data.id === action.payload.questionId) {
          return {
            ...item,
            data: {
              ...item.data,
              answer: action.payload.answer,
            },
          }
        }
        return item
      })
    },

    clearTimeline: (state: TimelineStateType) => {
      state.timelineItems = []
    },

    clearPrivacySettings: (state: TimelineStateType) => {
      state.currentQuestionPrivacySettings = initialState.currentQuestionPrivacySettings
      state.currentResultPrivacySettings = initialState.currentResultPrivacySettings
    },

    hideGroupedTimelineItems: (state: TimelineStateType) => {
      state.timelineItems = state.timelineItems.filter((item) => !item.data.groupParentId)
      state.expandedItemId = ''
    },
    updateLastMessage: (
      state: TimelineStateType,
      action: PayloadAction<MentionLastMessageUpdate>
    ) => {
      const { modelState } = action.payload
      let groupedMention: TimelineCompressedMentions | undefined = undefined
      let mention = state.timelineItems.find((item) =>
        item.type === TimelineItemType.Mention ? item.data.id === action.payload.mentionId : false
      )?.data as TimelineMention
      if (!mention) {
        for (const item of state.timelineItems) {
          if (item.type === TimelineItemType.Compressed) {
            mention = item.data.mentions.find(
              (x) => x.id === action.payload.mentionId
            ) as TimelineMention
            if (mention) {
              groupedMention = item.data as TimelineCompressedMentions
              break
            }
          }
        }
      }
      if (!mention) return
      if (modelState === ModelState.Deleted) {
        mention.lastMessage = action.payload.message
        const messageCount = mention?.messages ?? 0
        if (messageCount > 0) {
          mention.messages = messageCount - 1
        }
      } else if (modelState === ModelState.Created) {
        mention.lastMessage = action.payload.message
        mention.messages = (mention.messages ?? 0) + 1
      } else if (modelState === ModelState.Modified) {
        mention.lastMessage = action.payload.message
      }
      state.timelineItems = state.timelineItems.sort(sortTimelineItems)
      if (groupedMention) {
        groupedMention.mentions = groupedMention.mentions.sort(sortMentions)
        groupedMention.unreadMessageCount = groupedMention.mentions.reduce(
          (partialSum, a) => partialSum + (a.messages || 0),
          0
        )
      }
    },
    deleteItem: (state: TimelineStateType, action: PayloadAction<string>) => {
      let index = state.timelineItems.findIndex(
        (item) => item.type === TimelineItemType.Mention && item.data.id === action.payload
      )
      if (index !== -1) {
        state.timelineItems.splice(index, 1)
        return
      }
      for (const item of state.timelineItems) {
        if (item.type === TimelineItemType.Compressed) {
          index = item.data.mentions.findIndex((x) => x.id === action.payload)
          if (index !== -1) {
            item.data.mentions.splice(index, 1)
            return
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimelineItems.fulfilled, (state, action) => {
      state.timelineItems = action.payload.timelineItems
      state.isLastPage = action.payload.isLastPage
      state.page = action.payload.page
      state.isLoading = false
      state.success = true
      state.error = null
    })

    builder.addCase(getTimelineItems.pending, (state) => {
      state.success = false
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getTimelineItems.rejected, (state, action) => {
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
      state.isLoading = false
      state.success = false
    })

    builder.addCase(loadMoreTimelineItems.fulfilled, (state, action) => {
      if (action.payload) {
        state.timelineItems = [...state.timelineItems, ...action.payload.timelineItems]
        state.isLastPage = action.payload.isLastPage
        state.page = action.payload.page
      }
    })

    builder.addCase(getGroupedResults.pending, (state, action) => {
      state.expandLoadingId = action.meta.arg.timelineItemId
    })
    builder.addCase(getGroupedResults.rejected, (state) => {
      state.expandLoadingId = ''
    })

    builder.addCase(getGroupedResults.fulfilled, (state, action) => {
      const timelineItemsDefault = state.timelineItems.filter((item) => !item.data.groupParentId)
      const groupParentIndex = timelineItemsDefault.findIndex(
        (item) => item.id === action.payload.timelineItemId
      )

      if (groupParentIndex < 0) return state

      state.expandedItemId = action.payload.timelineItemId
      state.timelineItems = [
        ...timelineItemsDefault.slice(0, groupParentIndex + 1),
        ...action.payload.timelineItems,
        ...timelineItemsDefault.slice(groupParentIndex + 1),
      ]
      state.expandLoadingId = ''
    })

    builder.addCase(getGroupedEpisodes.pending, (state, action) => {
      state.expandLoadingId = action.meta.arg.timelineItemId
    })
    builder.addCase(getGroupedEpisodes.rejected, (state) => {
      state.expandLoadingId = ''
    })
    builder.addCase(getGroupedEpisodes.fulfilled, (state, action) => {
      const timelineItemsDefault = state.timelineItems.filter((item) => !item.data.groupParentId)
      const groupParentIndex = timelineItemsDefault.findIndex(
        (item) => item.id === action.payload.timelineItemId
      )

      if (groupParentIndex < 0) return state

      state.expandedItemId = action.payload.timelineItemId
      state.timelineItems = [
        ...timelineItemsDefault.slice(0, groupParentIndex + 1),
        ...action.payload.timelineItems,
        ...timelineItemsDefault.slice(groupParentIndex + 1),
      ]
      state.expandLoadingId = ''
    })

    builder.addCase(getQuestionPrivacySettings.fulfilled, (state, action) => {
      state.currentQuestionPrivacySettings = action.payload
    })

    builder.addCase(getResultPrivacySettings.fulfilled, (state, action) => {
      state.currentResultPrivacySettings = action.payload
    })
    builder.addCase(getAvailableAppSettings.fulfilled, (state, action) => {
      state.availableAppSettings = action.payload
    })
    builder.addCase(toggleHighlightPostItem.fulfilled, (state, action) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.data.postId === action.payload) {
          return {
            ...item,
            data: {
              ...item.data,
              isHighlighted: !item.data.isHighlighted,
            },
          }
        }
        return item
      })
    })
    builder.addCase(readPost.fulfilled, (state, action) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            data: {
              ...item.data,
              isRead: true,
              unreadCommentsCount: 0,
            },
          }
        }
        return item
      })
    })
    builder.addCase(answerPost.fulfilled, (state, action) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.id === action.payload.timelineItemId) {
          return {
            ...item,
            data: {
              ...item.data,
              answer: action.payload.answer,
              isRead: true,
              unreadCommentsCount: 0,
            },
          }
        }
        return item
      })
    })
    builder.addCase(getNewTimelineItem.fulfilled, (state, action) => {
      if (action.payload) {
        state.timelineItems = state.timelineItems.filter((x) => x.id !== action.payload?.id)
        state.timelineItems.unshift(action.payload)
      }
    })
    builder.addCase(getUpdatedTimelineItem.fulfilled, (state, action) => {
      if (action.payload) {
        const index = state.timelineItems.findIndex((item) => item.id === action.payload?.id)
        if (index !== -1) {
          state.timelineItems.splice(index, 1, action.payload)
        }
        state.timelineItems.push(action.payload)
      }
    })

    builder.addMatcher(
      isFulfilled(getQuestionPrivacySettings, getResultPrivacySettings),
      (state) => {
        state.success = true
        state.isLoading = false
        state.error = null
      }
    )

    builder.addMatcher(isPending(getQuestionPrivacySettings, getResultPrivacySettings), (state) => {
      state.isLoading = true
      state.error = null
      state.success = false
    })
    builder.addMatcher(
      isRejectedWithValue(getQuestionPrivacySettings, getResultPrivacySettings),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.isLoading = false
        state.success = false
      }
    )
  },
})

export const timelineReducer = timelineSlice.reducer
export const {
  setIsAnswered,
  setIsSaved,
  setQuestionAnswer,
  clearPrivacySettings,
  clearTimeline,
  hideGroupedTimelineItems,
  updateLastMessage,
  deleteItem,
} = timelineSlice.actions
