import React from 'react'
import styled from 'styled-components/macro'

interface IProps {
  children: React.ReactNode
  className?: string
}

const MentionWrapper: React.FC<IProps> = ({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>
}

export default MentionWrapper

const Wrapper = styled.div`
  height: calc(100vh - 60px);
  overflow-y: auto;
  width: 100%;
  padding-bottom: 52px;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  -webkit-overflow-scrolling: touch;

  @supports (-webkit-touch-callout: none) {
    height: calc(100vh - 150px);
  }
`
