import React from 'react'
import { TimelineMention, TimelineMentionType } from 'store/types'
import TimelineMentionLayout from 'layouts/TimelineMentionLayout'
import { MediaContent } from 'modules/timeline/components/ComponentsTimeline/MediaContent'
import { useBrandingColors } from 'hooks/useBrandingColors'
import { useTranslation } from 'react-i18next'
import { MentionHeader, MentionText } from '../styles'

interface IProps {
  mention: TimelineMention
  hasRecipient: boolean
  renderLastMessage: () => JSX.Element
  isGrouped?: boolean
}

const ComplimentItem: React.FC<IProps> = ({
  mention: { sender, recipient, media, title, messages, id },
  hasRecipient,
  renderLastMessage,
  isGrouped,
}) => {
  const { text: textColor } = useBrandingColors()
  const { t } = useTranslation()
  const getFromForText = (sender: string, recipient: string | undefined) => {
    if (hasRecipient) {
      return `${t('TIMELINE_ITEM.MENTION_FROM')} ${sender} ${t(
        'TIMELINE_ITEM.MENTION_FOR'
      )} ${recipient} `
    }
    return `${t('TIMELINE_ITEM.MENTION_FROM')} ${sender}`
  }

  return (
    <TimelineMentionLayout
      customMargin={isGrouped ? 0 : undefined}
      isGrouped={isGrouped}
      isRecipientGroup={recipient?.isGroup || false}
      iconSender={sender?.logo}
      iconRecipient={recipient?.logo}
      hasRecipient={hasRecipient}
      unreadMessagesCount={messages}
      mentionType={TimelineMentionType.Suggestion}
    >
      <MentionText textColor={textColor}>{title}</MentionText>
      <MentionHeader textColor={textColor}>
        {getFromForText(sender.fullName, recipient?.fullName)}
      </MentionHeader>
      <MediaContent media={media} itemId={id} />

      {renderLastMessage && renderLastMessage()}
    </TimelineMentionLayout>
  )
}

export default ComplimentItem
