import {
  saveNote,
  getComments,
  getComplaintPrivacySettings,
  getMentionPrivacySettings,
  getNote,
  getExtraTips,
  addComment,
  createCompliment,
  createTip,
  createComplaint,
  getComplaintChatPrivacySettings,
  getMentionChatPrivacySettings,
  createTopic,
  getTopics,
  getMentionInfoUpdate,
  updateTopic,
  newTopicListItem,
  updateTopicListItem,
} from 'store/slices/mentions/thunks'
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit'
import { CommentModel, MentionsState, NoteModel } from './types'
import {
  ComplaintInfo,
  MentionInfoUpdate,
  MentionRecipient,
  ModelState,
  NewPositiveMentionInfo,
  MentionLastMessageUpdate,
  SearchUserAndGroup,
} from 'store/types'

const initialState: MentionsState = {
  selectedUser: null,
  recipient: null,
  handlerUser: null,
  listTopics: { data: [], total: 0 },
  mentionUpdate: {} as MentionInfoUpdate,
  mention: {
    recipientId: '',
    media: {
      source: '',
      type: 0,
    },
    title: undefined,
    text: '',
    handlerId: '',
    isAnonymous: null,
    comment: '',
  },
  note: {
    id: '',
    questionId: '',
    text: '',
  },
  comment: {
    total: 0,
    data: [],
  },
  support: {
    total: 0,
    data: [],
  },
  mentionPrivacySettings: {} as NewPositiveMentionInfo,
  complaintPrivacySettings: {} as ComplaintInfo,
  isMentionListLoading: false,
  isLoading: false,
  error: null,
  success: false,
}

const mentionsSlice = createSlice({
  name: 'mentions',
  initialState,
  reducers: {
    setSelectedUser: (state, action: PayloadAction<SearchUserAndGroup>) => {
      state.selectedUser = action.payload
    },
    setHandlerUser: (state, action: PayloadAction<MentionRecipient>) => {
      state.handlerUser = action.payload
    },
    setRecipient: (state, action: PayloadAction<MentionRecipient>) => {
      state.recipient = action.payload
    },
    setMentionText: (state, action: PayloadAction<string>) => {
      state.mention.text = action.payload
    },
    setMentionComment: (state, action: PayloadAction<string>) => {
      state.mention.comment = action.payload
    },
    setMentionTitle: (state, action: PayloadAction<string>) => {
      state.mention.title = action.payload
    },

    setMentionMedia: (state, action: PayloadAction<{ source: string; type: number }>) => {
      state.mention.media.source = action.payload.source
      state.mention.media.type = action.payload.type
    },

    setIsMentionAnonymous: (state, action: PayloadAction<boolean>) => {
      state.mention.isAnonymous = action.payload
    },

    resetMention: (state) => {
      state.mention = initialState.mention
      state.selectedUser = null
      state.handlerUser = null
      state.recipient = null
    },
    setNote: (state, action: PayloadAction<NoteModel>) => {
      state.note = action.payload
    },
    resetNote: (state) => {
      state.note = initialState.note
    },
    setComment: (state, action: PayloadAction<CommentModel>) => {
      state.comment = action.payload
    },
    setMentionUpdate: (state, action: PayloadAction<MentionInfoUpdate>) => {
      state.mentionUpdate = action.payload
    },
    deleteMentionListItem: (state, action: PayloadAction<string>) => {
      const index = state.listTopics.data.findIndex((item) => item.id === action.payload)
      if (index !== -1) {
        state.listTopics.data.splice(index, 1)
      }
    },
    updateLastMessage: (state, action: PayloadAction<MentionLastMessageUpdate>) => {
      const { modelState } = action.payload
      const index = state.listTopics.data.findIndex((item) => item.id === action.payload.mentionId)
      if (index === -1) return
      if (modelState === ModelState.Deleted) {
        state.listTopics.data[index].lastMessage = action.payload.message
        const messageCount = state.listTopics.data[index]?.messages ?? 0
        if (messageCount > 0) {
          state.listTopics.data[index].messages = messageCount - 1
        }
      } else if (modelState === ModelState.Created) {
        state.listTopics.data[index].lastMessage = action.payload.message
        state.listTopics.data[index].messages = (state.listTopics.data[index]?.messages ?? 0) + 1
      } else if (modelState === ModelState.Modified) {
        state.listTopics.data[index].lastMessage = action.payload.message
      }
      state.listTopics.data = state.listTopics.data.sort((a, b) => {
        return (
          new Date(b.lastMessage?.createdDate || b.date || '').getTime() -
          new Date(a.lastMessage?.createdDate || a.date || '').getTime()
        )
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMentionInfoUpdate.fulfilled, (state, action) => {
      state.mentionUpdate = action.payload
    })
    builder.addCase(getMentionPrivacySettings.fulfilled, (state, action) => {
      state.mentionPrivacySettings = action.payload
    })
    builder.addCase(getComplaintPrivacySettings.fulfilled, (state, action) => {
      state.complaintPrivacySettings = action.payload
    })
    builder.addCase(getComplaintChatPrivacySettings.fulfilled, (state, action) => {
      state.complaintPrivacySettings = action.payload
    })
    builder.addCase(getMentionChatPrivacySettings.fulfilled, (state, action) => {
      state.mentionPrivacySettings = action.payload
    })
    builder.addCase(newTopicListItem.fulfilled, (state, action) => {
      state.listTopics.data.unshift(action.payload)
    })
    builder.addCase(updateTopicListItem.fulfilled, (state, action) => {
      const index = state.listTopics.data.findIndex((item) => item.id === action.payload.id)
      if (index !== -1) {
        state.listTopics.data.splice(index, 1, action.payload)
      }
    })

    builder.addCase(getTopics.fulfilled, (state, action) => {
      state.isMentionListLoading = false
      if (action.payload.isLoadMore) {
        state.listTopics.data = [...state.listTopics.data, ...action.payload.data]
        state.listTopics.total = action.payload.total
      } else {
        state.listTopics.data = action.payload.data
        state.listTopics.total = action.payload.total
      }
    })
    builder.addCase(getTopics.pending, (state) => {
      state.isMentionListLoading = true
    })
    builder.addCase(getComments.fulfilled, (state, action) => {
      state.comment.data = action.payload.data
      state.comment.total = action.payload.total
    })

    builder.addCase(getExtraTips.fulfilled, (state, action) => {
      state.support.data = action.payload.data
      state.support.total = action.payload.total
    })

    builder.addCase(saveNote.fulfilled, (state, action) => {
      state.note = action.payload
    })

    builder.addMatcher(
      isFulfilled(
        addComment,
        createCompliment,
        createTip,
        createComplaint,
        createTopic,
        updateTopic
      ),
      (state) => {
        state.mention = initialState.mention
        state.selectedUser = null
        state.handlerUser = null
        state.isLoading = false
        state.error = null
        state.success = true
      }
    )
    builder.addMatcher(
      isPending(addComment, createCompliment, createTip, createComplaint, createTopic),
      (state) => {
        state.mention = initialState.mention
        state.selectedUser = null
        state.handlerUser = null
        state.isLoading = true
        state.error = null
        state.success = false
      }
    )
    builder.addMatcher(
      isRejectedWithValue(
        addComment,
        createCompliment,
        createTip,
        createComplaint,
        createTopic,
        updateTopic
      ),
      (state, action) => {
        state.mention = initialState.mention
        state.selectedUser = null
        state.handlerUser = null
        if (action.payload) {
          state.error = {
            message: action.payload.message,
          }
        }
        state.isLoading = false
        state.success = false
      }
    )

    builder.addMatcher(
      isFulfilled(
        getNote,
        getComments,
        getExtraTips,
        saveNote,
        getComplaintPrivacySettings,
        getComplaintChatPrivacySettings,
        getMentionChatPrivacySettings,
        getMentionPrivacySettings,
        getMentionInfoUpdate,
        updateTopic
      ),
      (state) => {
        state.isLoading = false
        state.error = null
        state.success = true
      }
    )

    builder.addMatcher(
      isPending(
        getNote,
        getComments,
        getExtraTips,
        saveNote,
        getComplaintPrivacySettings,
        getComplaintChatPrivacySettings,
        getMentionChatPrivacySettings,
        getMentionPrivacySettings,
        getMentionInfoUpdate,
        updateTopic
      ),
      (state) => {
        state.isLoading = true
        state.error = null
        state.success = false
      }
    )

    builder.addMatcher(
      isRejectedWithValue(
        getNote,
        getComments,
        getExtraTips,
        saveNote,
        getComplaintPrivacySettings,
        getComplaintChatPrivacySettings,
        getMentionChatPrivacySettings,
        getMentionPrivacySettings,
        getMentionInfoUpdate,
        updateTopic,
        getTopics
      ),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.isMentionListLoading = false
        state.isLoading = false
        state.success = false
      }
    )
  },
})

export const mentionsReducer = mentionsSlice.reducer
export const {
  setSelectedUser,
  setHandlerUser,
  setIsMentionAnonymous,
  setMentionText,
  setMentionTitle,
  setMentionMedia,
  setMentionComment,
  resetMention,
  setNote,
  resetNote,
  setComment,
  setRecipient,
  setMentionUpdate,
  deleteMentionListItem,
  updateLastMessage,
} = mentionsSlice.actions
