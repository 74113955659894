import { Media } from './timelineMedia'
import { User } from '.'
import { LastMessage } from 'modules/timeline/components/TimelineItems/MentionItem'

export enum TimelineMentionType {
  Compliment,
  Complaint,
  Suggestion,
  Note,
  Comment,
  Support,
  Topic,
}

export enum MentionChatType {
  Complaint,
  Compliment,
  Suggestion,
  Comment = 4,
  Topic = 6,
}

export interface LastMessage {
  message: string
  logo: string | null
  createdDate: string
}

export interface TimelineMention {
  id: string
  media: Media | null
  title: string
  messages?: number
  mentionType: TimelineMentionType
  isHandler: boolean | null
  recipient: User | null
  sender: User
  date?: string
  isAnon: boolean | null
  lastUpdated?: string
  withinGroup?: boolean
  lastMessage: LastMessage
}

export enum ModelState {
  Unmodified = 0,
  Created = 1,
  Modified = 2,
  Deleted = 3,
}

export interface MentionLastMessageUpdate {
  message: LastMessage
  mentionId: string
  modelState: ModelState
}

export interface TimelineCompressedMentions {
  id: string
  appName: string
  groupName: string
  logo: null | string
  unreadMessageCount: number
  mentionType: TimelineMentionType
  mentions: TimelineMention[]
}

export interface MentionAttachment {
  documentTitle: string | null
  content: string | null
  videoId: string | null
  thumbnail: string | null
  giphy: string | null
  videoUrl: string | null
}
