import React from 'react'
import Loader from 'modules/common/components/Loader'
import { useSelector } from 'react-redux'
import { selectIsLoading as isLoadingAuth } from 'store/slices/auth/selectors'
import { selectIsLoading as isEpisodeLoading } from 'store/slices/episode/selectors'
import { selectIsLoading as isAboutMeLoading } from 'store/slices/about-me/selectors'
import { selectIsLoading as isAboutOthersLoading } from 'store/slices/about-others/selectors'
import { selectIsLoading as isTimelineLoading } from 'store/slices/timeline/selectors'
import { selectIsLoading as isPasswordCheckLoading } from 'store/slices/password-check/slice'
import { selectIsLoading as isSearchResultLoading } from 'store/slices/search/selectors'
import { isMentionsLoading } from 'store/slices/mentions/selectors'
import { isUserLoading } from 'store/slices/user/selectors'
import { selectIsLoading as isNewGroupLoading } from 'store/slices/new-group/selectors'
import { selectIsLoading as isNewGroupRegistrationLoading } from 'store/slices/new-group-registration/selectors'
import { selectIsLoading as isMentionChatLoading } from 'store/slices/mention-chat/selectors'

const LoaderContainer = () => {
  const loaders = {
    auth: useSelector(isLoadingAuth),
    episode: useSelector(isEpisodeLoading),
    aboutMe: useSelector(isAboutMeLoading),
    aboutOthers: useSelector(isAboutOthersLoading),
    user: useSelector(isUserLoading),
    mentions: useSelector(isMentionsLoading),
    timeline: useSelector(isTimelineLoading),
    passwordCheck: useSelector(isPasswordCheckLoading),
    search: useSelector(isSearchResultLoading),
    newGroup: useSelector(isNewGroupLoading),
    newGroupReistration: useSelector(isNewGroupRegistrationLoading),
    mentionChat: useSelector(isMentionChatLoading),
  }

  const isLoading = Object.values(loaders).some((loader) => loader === true)

  return <Loader isLoading={isLoading} />
}

export default LoaderContainer
