import { createAsyncThunk } from '@reduxjs/toolkit'
import { UploadApi } from 'api/upload'
import { AxiosError } from 'axios'
import { MediaType } from 'store/types'
import { setMentionMedia } from '../mentions/slice'
import { InitUploadType, UploadingFileType } from './types'

interface Error {
  message: string
}

export const initMultipartUpload = createAsyncThunk<
  UploadingFileType,
  InitUploadType,
  { rejectValue: Error }
>(
  'upload/initMultipartUpload',
  async ({ fileName, size, bucketType, contentType, file, mentionId }, { rejectWithValue }) => {
    try {
      const data = await UploadApi.initMultipartUploadRequest({
        fileName,
        size,
        bucketType,
        contentType,
        mentionId,
      })

      return {
        ...data,
        bucketType,
        file,
      }
    } catch (err) {
      const error = err as AxiosError

      if (error) {
        return rejectWithValue({ message: error.message })
      }
      throw err
    }
  }
)

export const uploadFile = createAsyncThunk<
  { source: string; type: number },
  any,
  { rejectValue: Error }
>('upload/uploadFile', async (file, { rejectWithValue, dispatch }) => {
  try {
    const imageUrl = await UploadApi.uploadFileRequest(file)

    const mediaData = { source: imageUrl, type: MediaType.IMAGE }

    dispatch(setMentionMedia(mediaData))

    return mediaData
  } catch (err) {
    const error = err as AxiosError

    if (error) {
      return rejectWithValue({ message: error.message })
    }
    throw err
  }
})
