import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { MentionsApi } from 'api/mentions'
import {
  AddComplaintRequestModel,
  AddMentionRequestModel,
  CommentRequestModel,
} from 'api/types/requestTypes'
import { CommentModel, Error, NoteModel } from './types'
import { resetMention, setNote } from './slice'
import {
  ComplaintInfo,
  MentionInfoUpdate,
  NewPositiveMentionInfo,
  SupportsModel,
  TimelineMention,
  TimelineMentionType,
  TopicUpdate,
} from 'store/types'
import { ComplaintResponse, NoteResponse } from 'api/mentions/types/response'

export const createComplaint = createAsyncThunk<
  void,
  AddComplaintRequestModel,
  { rejectValue: Error }
>('mentions/createComplaint', async (complaintData, { rejectWithValue, dispatch }) => {
  try {
    await MentionsApi.postComplaintRequest(complaintData)
    dispatch(resetMention())
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const createTip = createAsyncThunk<void, AddMentionRequestModel, { rejectValue: Error }>(
  'mentions/createTip',
  async (tipData, { rejectWithValue, dispatch }) => {
    try {
      await MentionsApi.postTipRequest(tipData)
      dispatch(resetMention())
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const createCompliment = createAsyncThunk<
  void,
  AddMentionRequestModel,
  { rejectValue: Error }
>('mentions/createCompliment', async (complimentData, { rejectWithValue, dispatch }) => {
  try {
    await MentionsApi.postComplimentRequest(complimentData)
    dispatch(resetMention())
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const createTopic = createAsyncThunk<
  ComplaintResponse,
  AddMentionRequestModel,
  { rejectValue: Error }
>('mentions/createTopic', async (topicData, { rejectWithValue }) => {
  try {
    return await MentionsApi.postTopicRequest(topicData)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getNote = createAsyncThunk<any, string, { rejectValue: Error }>(
  'mentions/getNote',
  async (questionId, { rejectWithValue, dispatch }) => {
    try {
      const data = await MentionsApi.getNote(questionId)
      dispatch(setNote(data))
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const saveNote = createAsyncThunk<NoteResponse, NoteModel, { rejectValue: Error }>(
  'mentions/saveNote',
  async (note, { rejectWithValue }) => {
    try {
      if (note.id === '00000000-0000-0000-0000-000000000000') {
        return await MentionsApi.postNote(note)
      }

      return await MentionsApi.updateNote(note)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const addComment = createAsyncThunk<any, CommentRequestModel, { rejectValue: Error }>(
  'mentions/addComment',
  async (comment, { rejectWithValue }) => {
    try {
      return await MentionsApi.postComment(comment)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getComments = createAsyncThunk<CommentModel, string, { rejectValue: Error }>(
  'mentions/getComments',
  async (questionId, { rejectWithValue }) => {
    try {
      const { data, total } = await MentionsApi.getCommentsRequest(questionId)
      return {
        data,
        total,
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getExtraTips = createAsyncThunk<SupportsModel, string, { rejectValue: Error }>(
  'mentions/getExtraTips',
  async (questionId, { rejectWithValue }) => {
    try {
      const data = await MentionsApi.getExtraTipsRequest(questionId)

      return {
        data,
        total: data.length,
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getMentionPrivacySettings = createAsyncThunk<
  NewPositiveMentionInfo,
  { recipientId: string; isCompliment: boolean },
  { rejectValue: Error }
>(
  'mentions/getMentionPrivacySettings',
  async ({ recipientId, isCompliment }, { rejectWithValue }) => {
    try {
      return await MentionsApi.getInfoRequest(recipientId, isCompliment)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getComplaintPrivacySettings = createAsyncThunk<
  ComplaintInfo,
  { recipientId: string; handlerId: string; isAnonymous: boolean },
  { rejectValue: Error }
>(
  'mentions/getComplaintPrivacySettings',
  async ({ recipientId, handlerId, isAnonymous }, { rejectWithValue }) => {
    try {
      return await MentionsApi.getComplaintInfoRequest(recipientId, handlerId, isAnonymous)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getComplaintChatPrivacySettings = createAsyncThunk<
  ComplaintInfo,
  string,
  { rejectValue: Error }
>('mentions/getComplaintChatPrivacySettings', async (mentionId, { rejectWithValue }) => {
  try {
    return await MentionsApi.getComplaintChatPrivacyRequest(mentionId)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getMentionChatPrivacySettings = createAsyncThunk<
  NewPositiveMentionInfo,
  string,
  { rejectValue: Error }
>('mentions/getMentionChatPrivacySettings', async (mentionId, { rejectWithValue }) => {
  try {
    return await MentionsApi.getMentionChatPrivacyRequest(mentionId)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getTopics = createAsyncThunk<
  { data: TimelineMention[]; total: number; isLoadMore: boolean },
  {
    page: number
    title?: string | undefined
    recipient?: string | undefined
    isLoadMore: boolean
    appSettingId: string
  },
  { rejectValue: Error }
>(
  'mentions/getTopics',
  async ({ page, title, recipient, isLoadMore, appSettingId }, { rejectWithValue }) => {
    try {
      const data = await MentionsApi.getListTopics(topicQuery(title, page, appSettingId), recipient)
      const result = { ...data, isLoadMore }
      return result
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getMentionInfoUpdate = createAsyncThunk<
  MentionInfoUpdate,
  string,
  { rejectValue: Error }
>('mentions/getMentionInfoUpdate', async (mentionId, { rejectWithValue }) => {
  try {
    return await MentionsApi.getMentionInfoUpdate(mentionId)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const updateTopic = createAsyncThunk<
  ComplaintResponse,
  { mentionId: string; model: TopicUpdate },
  { rejectValue: Error }
>('mentions/updateTopic', async ({ mentionId, model }, { rejectWithValue }) => {
  try {
    return await MentionsApi.updateTopic(mentionId, model)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const newTopicListItem = createAsyncThunk<TimelineMention, string, { rejectValue: Error }>(
  'mentions/newTopicListItem',
  async (mentionId, { rejectWithValue }) => {
    try {
      return await MentionsApi.getListItem(mentionId)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const updateTopicListItem = createAsyncThunk<
  TimelineMention,
  string,
  { rejectValue: Error }
>('mentions/updateTopicListItem', async (mentionId, { rejectWithValue }) => {
  try {
    return await MentionsApi.getListItem(mentionId)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

const topicQuery = (title: string | undefined, page: number, appSettingId: string) => {
  return {
    pager: {
      current: page,
      pageSize: 15,
    },
    sorter: [],
    filters: [
      { field: 'mentionType', value: TimelineMentionType.Topic, operator: 'eq', logic: 'and' },
      { field: 'appSettingId', value: appSettingId, operator: 'eq', logic: 'and' },
    ],
    search: {
      fields: ['title'],
      value: title || '',
    },
  }
}
