import { useBrandingColors } from 'hooks/useBrandingColors'
import React from 'react'

export const FilterIcon = ({
  iconColor,
  className,
  onClick,
}: {
  iconColor?: string | undefined
  className?: string | undefined
  onClick: () => void
}) => {
  const { icon } = useBrandingColors()
  return (
    <svg
      onClick={onClick}
      className={className}
      id="Group_4"
      data-name="Group 4"
      width="28"
      height="28"
      viewBox="0 0 18 18.004"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_2" data-name="Rectangle 2" width="18" height="18.004" fill="#fff" />
        </clipPath>
      </defs>
      <g id="Group_3" data-name="Group 3" clipPath="url(#clip-path)">
        <path
          id="Path_3"
          data-name="Path 3"
          d="M12.593,3.595q-2.25,0-4.5,0a.174.174,0,0,0-.186.125A2.692,2.692,0,0,1,5.54,5.387,2.645,2.645,0,0,1,2.925,3.723a.174.174,0,0,0-.194-.131q-.885.008-1.77,0A.918.918,0,0,1,.076,3.04a.9.9,0,0,1,.7-1.232,1.817,1.817,0,0,1,.269-.019c.568,0,1.135,0,1.7,0a.159.159,0,0,0,.179-.115A2.626,2.626,0,0,1,5.527.007,2.693,2.693,0,0,1,7.9,1.65a.2.2,0,0,0,.217.141q4.425-.005,8.85,0a.982.982,0,0,1,.888.441.892.892,0,0,1-.708,1.361c-.063.005-.126.005-.189.005H12.593"
          transform="translate(0 -0.001)"
          fill={iconColor || icon}
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M14.272,181.6c-1.248,0-2.5,0-3.743,0a.206.206,0,0,0-.228.146,2.741,2.741,0,0,1-2.722,1.639,2.619,2.619,0,0,1-2.245-1.646.188.188,0,0,0-.2-.138q-1.52.007-3.04,0a.9.9,0,1,1-.011-1.8q1.513-.005,3.027,0a.216.216,0,0,0,.235-.153A2.593,2.593,0,0,1,7.727,178a2.7,2.7,0,0,1,2.579,1.657.2.2,0,0,0,.217.14q3.736-.005,7.472,0a.9.9,0,0,1,.2,1.793,2.022,2.022,0,0,1-.242.013H14.272"
          transform="translate(-1.054 -165.39)"
          fill={iconColor || icon}
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M17.266,92.539c-.293,0-.586.005-.878,0a.172.172,0,0,0-.188.124,2.668,2.668,0,0,1-2.31,1.667,2.555,2.555,0,0,1-2.474-1.267,2.647,2.647,0,0,1-.2-.409.15.15,0,0,0-.166-.114c-.265.008-.531,0-.8,0q-4.046,0-8.093,0a.98.98,0,0,1-.675-.219.895.895,0,0,1,.495-1.569c.076-.007.153-.007.229-.007q4.411,0,8.822,0a.167.167,0,0,0,.187-.122,2.666,2.666,0,0,1,4.2-1.053,2.711,2.711,0,0,1,.8,1.071.144.144,0,0,0,.159.1q.844-.005,1.689,0a.895.895,0,0,1,.113,1.786c-.3.011-.6,0-.905,0v0"
          transform="translate(-1.085 -82.643)"
          fill={iconColor || icon}
        />
      </g>
    </svg>
  )
}
