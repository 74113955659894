import { createAsyncThunk } from '@reduxjs/toolkit'
import i18n from 'i18next'
import { Error } from './types'
import { AxiosError } from 'axios'
import { AuthApi } from 'api/auth'
import { removeFromLocalStorage, saveToLocalStorage } from 'utils/storage'
import { LOCAL_STORAGE_KEYS } from 'modules/common/constants/storageKeys'
import history from 'utils/history'
import { AppRoutes, AuthRoutes } from 'router/routesConfig'
import { RootState } from 'store'
import { setBrandingColors, setOrganizationMentions } from 'store/slices/UI/slice'
import { Organization, OrganizationMentions } from 'store/types'
import { clearUserInfo, setCurrentUser } from '../user/slice'
import { setOrgUrl } from 'api/helpers'
import { MasterUserApi } from 'api/masterUser'
import { clearTimeline } from '../timeline/slice'
import { preloadUserData } from '../UI/thunks'
import { addUserToConfirmed, resetAuthStep, setAuthStep, setCurrentUserLoginDetails } from './slice'
import { base64ToBlob } from 'modules/common/helpers'
import { GetLanguagesResponse, LanguageType, OrganizationResponse } from 'api/auth/types/response'
import { getUrlByStep, LoginMethod, RegistrationStep } from 'modules/auth/constants/enums'
import { getLanguageCodeById, getLanguageCodeByName } from 'utils/languageCodes'
import { setMessage } from '../feedback/slice'

export const getMessagingUrl = createAsyncThunk<
  void,
  { organizationId: string },
  { rejectValue: Error }
>('auth/getMessagingUrl', async ({ organizationId }, { rejectWithValue }) => {
  try {
    const url = await AuthApi.getMessagingUrl(organizationId)
    saveToLocalStorage(LOCAL_STORAGE_KEYS.MESSAGING_URL, url)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const forgotPassword = createAsyncThunk<
  void,
  { loginData: string; loginMethod: 0 | 1 },
  { rejectValue: Error }
>('auth/forgotPassword', async ({ loginData, loginMethod }, { rejectWithValue, dispatch }) => {
  try {
    await AuthApi.forgotPasswordRequest(loginData, loginMethod)

    if (loginMethod === LoginMethod.PhoneNumber) {
      dispatch(setMessage(i18n.t('AUTH_MODULE.FORGOT_PASSWORD_PHONE_SUCCESS')))
    }

    if (loginMethod === LoginMethod.Email) {
      dispatch(setMessage(i18n.t('AUTH_MODULE.FORGOT_PASSWORD_EMAIL_SUCCESS')))
    }
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const switchOrganization = createAsyncThunk<void, string, { rejectValue: Error }>(
  'auth/switchOrganization',
  async (organizationId, { rejectWithValue, dispatch, getState }) => {
    try {
      const {
        auth: { organizations },
      } = getState() as RootState

      const selectedOrganization = organizations.find(
        (org) => org.organizationId === organizationId
      )

      if (selectedOrganization) {
        setOrgUrl(`${selectedOrganization.hostingUrl}/`)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL, `${selectedOrganization.hostingUrl}/`)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.MESSAGING_URL, `${selectedOrganization.messagingUrl}`)
      }

      const user = await AuthApi.switchOrganizationRequest(organizationId, '')

      if (user.accessToken) {
        dispatch(setCurrentUser(user))
        saveToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, user.accessToken)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, user.refreshToken)

        const userLanguage = user.credentialsInfo.supportedLanguages.find(
          (lang) => lang.id === user.credentialsInfo.userLanguageId
        )?.name

        if (userLanguage) {
          const langCode = getLanguageCodeByName(userLanguage)

          saveToLocalStorage(LOCAL_STORAGE_KEYS.USER_LANGUAGE, JSON.stringify(langCode))
          i18n.changeLanguage(langCode)
        }

        if (!user.isMasterApi) {
          let orgMentions: OrganizationMentions

          const { data: brandingColors } = await AuthApi.organizationColorsRequest(organizationId)
          const {
            data: { mentions },
          } = await AuthApi.organizationMentionsRequest(organizationId)

          orgMentions = mentions

          if (!mentions) {
            const {
              ui: { masterOrganization },
            } = getState() as RootState

            const {
              data: { mentions },
            } = await AuthApi.masterOrganizationMentionsRequest(masterOrganization.id)
            orgMentions = mentions
          }

          if (orgMentions) {
            dispatch(setOrganizationMentions(orgMentions))
          }

          if (brandingColors && !brandingColors.StatusCode) {
            dispatch(setBrandingColors(brandingColors))
          }
        }

        history.push(AppRoutes.TIMELINE)
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        dispatch(setMessage(error.response?.data.Message))
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getUserOrganizations = createAsyncThunk<Organization[], void, { rejectValue: Error }>(
  'auth/getUserOrganizations',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data: availableOrganizations } = await MasterUserApi.getUserOrganizations()

      if (availableOrganizations.length === 1) {
        setOrgUrl(`${availableOrganizations[0].hostingUrl}/`)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL, `${availableOrganizations[0].hostingUrl}/`)
        dispatch(switchOrganization(availableOrganizations[0].organizationId))
      } else {
        history.push({ pathname: AppRoutes.ORGANIZATIONS, state: { password: '' } })
      }

      return availableOrganizations
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const logout = createAsyncThunk<void, void, { rejectValue: Error }>(
  'auth/logout',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(clearUserInfo())
      dispatch(clearTimeline())
      dispatch(preloadUserData())
      removeFromLocalStorage(LOCAL_STORAGE_KEYS.USER)
      saveToLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL, '')
      setOrgUrl('')
      dispatch(resetAuthStep())
      history.push(AuthRoutes.EMAIL)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const logoutAfterCredentialChange = createAsyncThunk<void, void, { rejectValue: Error }>(
  'auth/logoutAfterCredentialChange',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(clearUserInfo())
      dispatch(clearTimeline())
      //dispatch(preloadUserData())
      removeFromLocalStorage(LOCAL_STORAGE_KEYS.USER)
      saveToLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL, '')
      setOrgUrl('')
      dispatch(resetAuthStep())
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const sendAccessCode = createAsyncThunk<
  void,
  { loginData: string; loginMethod: 0 | 1; isResend?: boolean },
  { rejectValue: Error }
>(
  'auth/sendAccessCode',
  async ({ loginData, loginMethod, isResend = false }, { rejectWithValue, dispatch }) => {
    try {
      await AuthApi.sendAccessCodeRequest(loginData, loginMethod)

      if (isResend) {
        if (loginMethod === LoginMethod.PhoneNumber) {
          dispatch(setMessage(i18n.t('AUTH_MODULE.ACCESS_CODE_PHONE_SUCCESS')))
        }

        if (loginMethod === LoginMethod.Email) {
          dispatch(setMessage(i18n.t('AUTH_MODULE.ACCESS_CODE_EMAIL_SUCCESS')))
        }
      }

      history.push(AuthRoutes.ACCESS_CODE)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const saveAccessCode = createAsyncThunk<
  { registrationToken: string | null; registrationStep: number },
  { accessCode: string; loginData: string; loginMethod: 0 | 1 },
  { rejectValue: Error }
>(
  'auth/saveAccessCode',
  async ({ accessCode, loginData, loginMethod }, { rejectWithValue, dispatch }) => {
    try {
      const { registrationToken, isOk, errorMessage } = await AuthApi.saveAccessCodeRequest(
        accessCode,
        loginData,
        loginMethod
      )

      if (!isOk || errorMessage || !registrationToken) {
        return rejectWithValue({ message: errorMessage || 'An error occurred' })
      }

      const { registrationStep } = await AuthApi.getCurrentStepRequest(registrationToken)

      if (registrationStep === RegistrationStep.Completed) {
        dispatch(addUserToConfirmed({ loginData, registrationToken }))
        history.push(AuthRoutes.CHECK_PASSWORD)

        return {
          registrationToken,
          registrationStep,
        }
      }

      history.push(getUrlByStep(registrationStep))

      return {
        registrationToken,
        registrationStep,
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getCurrentStep = createAsyncThunk<
  { registrationStep: number; language: LanguageType; logo: string | null },
  void,
  { rejectValue: Error }
>('auth/getCurrentStep', async (_, { getState }) => {
  /*eslint-disable-next-line no-useless-catch*/
  try {
    const {
      auth: {
        currentUserLoginDetails: { registrationToken },
      },
    } = getState() as RootState

    const { registrationStep, language, logo } = await AuthApi.getCurrentStepRequest(
      registrationToken
    )

    return {
      registrationStep,
      language,
      logo,
    }
  } catch (err) {
    throw err
  }
})

export const checkIsRegistered = createAsyncThunk<
  void,
  { loginData: string; loginMethod: 0 | 1 },
  { rejectValue: Error }
>(
  'auth/checkIsRegistered',
  async ({ loginData, loginMethod }, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        auth: { confirmedUsers },
      } = getState() as RootState

      const confirmedUser = confirmedUsers.find((user) => user.loginData === loginData)

      const registrationToken =
        confirmedUser && confirmedUser.registrationToken ? confirmedUser.registrationToken : ''

      const isRegistered = await AuthApi.checkIsRegisteredRequest(
        loginData,
        loginMethod,
        registrationToken
      )

      dispatch(setCurrentUserLoginDetails({ loginData, loginMethod }))

      if (isRegistered) {
        history.push(AuthRoutes.CHECK_PASSWORD)
      }

      if (!isRegistered) {
        dispatch(sendAccessCode({ loginData, loginMethod }))
      }

      dispatch(setCurrentUserLoginDetails({ loginData, loginMethod }))
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getLanguages = createAsyncThunk<GetLanguagesResponse, void, { rejectValue: Error }>(
  'auth/getLanguages',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: {
          currentUserLoginDetails: { registrationToken },
        },
      } = getState() as RootState

      const languages = await AuthApi.getLanguagesRequest(registrationToken)

      return languages
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
export const saveLanguage = createAsyncThunk<void, number, { rejectValue: Error }>(
  'auth/saveLanguage',
  async (languageId, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        auth: {
          currentUserLoginDetails: { registrationToken },
        },
      } = getState() as RootState

      const res = await AuthApi.saveLanguageRequest(languageId, registrationToken)

      const langCode = getLanguageCodeById(languageId)

      saveToLocalStorage(LOCAL_STORAGE_KEYS.USER_LANGUAGE, JSON.stringify(langCode))
      i18n.changeLanguage(langCode)

      if (!res.isOk && res.errorMessage) {
        return rejectWithValue({ message: res.errorMessage })
      }

      dispatch(setAuthStep(RegistrationStep.PrivacyPolicy))

      history.push(AuthRoutes.PRIVACY_POLICY)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const saveUserLogo = createAsyncThunk<
  void,
  {
    logo: string | null
    fileName: string | null
    loginData: string
    loginMethod: 0 | 1
    password?: string
  },
  { rejectValue: Error }
>(
  'auth/saveUserLogo',
  async (
    { logo, fileName, loginData, loginMethod, password },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const {
        auth: {
          currentUserLoginDetails: { registrationToken },
        },
      } = getState() as RootState

      if (logo && fileName) {
        await AuthApi.saveUserLogoRequest({
          RegistrationToken: registrationToken,
          Logo: {
            file: base64ToBlob(logo),
            imageFilename: `${fileName}/${String(Date.now())}`,
          },
        })
      }

      if (!logo && !fileName) {
        await AuthApi.saveUserLogoRequest({
          RegistrationToken: registrationToken,
          Logo: null,
        })
      }

      if (password) {
        dispatch(
          getOrganizationsList({
            loginData,
            loginMethod,
            password,
          })
        )
      }

      if (!password) {
        history.push(AuthRoutes.CHECK_PASSWORD)
      }

      dispatch(addUserToConfirmed({ loginData, registrationToken }))
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const savePrivacyPolicy = createAsyncThunk<void, void, { rejectValue: Error }>(
  'auth/savePrivacyPolicy',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        auth: {
          currentUserLoginDetails: { registrationToken },
        },
      } = getState() as RootState

      await AuthApi.savePrivacyPolicyRequest(registrationToken)

      dispatch(setAuthStep(RegistrationStep.Password))

      history.push(AuthRoutes.PASSWORD)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const savePassword = createAsyncThunk<void, string, { rejectValue: Error }>(
  'auth/savePassword',
  async (password, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        auth: {
          currentUserLoginDetails: { registrationToken },
        },
      } = getState() as RootState

      const res = await AuthApi.savePasswordRequest(password, registrationToken)

      if (!res.isOk && res.errorMessage) {
        dispatch(setMessage(res.errorMessage))
        return rejectWithValue({ message: res.errorMessage })
      }

      history.push({
        pathname: AuthRoutes.LOGO,
        state: {
          password,
        },
      })

      dispatch(setAuthStep(RegistrationStep.Logo))
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getOrganizationsList = createAsyncThunk<
  OrganizationResponse[],
  { loginData: string; loginMethod: 0 | 1; password: string },
  { rejectValue: Error }
>(
  'auth/getOrganizationsList',
  async ({ loginData, loginMethod, password }, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        auth: {
          currentUserLoginDetails: { registrationToken },
        },
      } = getState() as RootState

      const organizations = await AuthApi.getOrganizationsRequest(
        loginData,
        loginMethod,
        registrationToken,
        password
      )

      if (organizations.length === 1) {
        setOrgUrl(`${organizations[0].hostingUrl}/`)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL, `${organizations[0].hostingUrl}/`)
        dispatch(
          loginToOrganization({
            loginData,
            loginMethod,
            registrationToken,
            password,
            organizationId: organizations[0].organizationId,
          })
        )
      } else {
        history.push({
          pathname: AppRoutes.ORGANIZATIONS,
          state: { password, loginMethod, loginData, registrationToken },
        })
      }

      return organizations
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const loginToOrganization = createAsyncThunk<
  void,
  {
    loginData: string
    loginMethod: 0 | 1
    registrationToken: string
    password: string
    organizationId: string
  },
  { rejectValue: Error }
>(
  'auth/loginToOrganization',
  async (
    { loginData, loginMethod, registrationToken, password, organizationId },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const {
        auth: { organizations },
      } = getState() as RootState

      const selectedOrganization = organizations.find(
        (org) => org.organizationId === organizationId
      )

      if (selectedOrganization) {
        setOrgUrl(`${selectedOrganization.hostingUrl}/`)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL, `${selectedOrganization.hostingUrl}/`)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.MESSAGING_URL, `${selectedOrganization.messagingUrl}`)
      }

      const user = await AuthApi.loginToOrganizationRequest(
        loginData,
        loginMethod,
        registrationToken,
        password,
        organizationId
      )

      if (user.accessToken) {
        dispatch(setCurrentUser(user))
        saveToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, user.accessToken)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, user.refreshToken)

        const userLanguage = user.credentialsInfo.supportedLanguages.find(
          (lang) => lang.id === user.credentialsInfo.userLanguageId
        )?.name

        if (userLanguage) {
          const langCode = getLanguageCodeByName(userLanguage)

          saveToLocalStorage(LOCAL_STORAGE_KEYS.USER_LANGUAGE, JSON.stringify(langCode))
          i18n.changeLanguage(langCode)
        }

        if (!user.isMasterApi) {
          let orgMentions: OrganizationMentions

          const { data: brandingColors } = await AuthApi.organizationColorsRequest(organizationId)
          const {
            data: { mentions },
          } = await AuthApi.organizationMentionsRequest(organizationId)

          orgMentions = mentions

          if (!mentions) {
            const {
              ui: { masterOrganization },
            } = getState() as RootState

            const {
              data: { mentions },
            } = await AuthApi.masterOrganizationMentionsRequest(masterOrganization.id)
            orgMentions = mentions
          }

          if (orgMentions) {
            dispatch(setOrganizationMentions(orgMentions))
          }

          if (brandingColors && !brandingColors.StatusCode) {
            dispatch(setBrandingColors(brandingColors))
          }
        }

        history.push(AppRoutes.TIMELINE)
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const loginAfterCredentialChange = createAsyncThunk<
  void,
  {
    loginData: string
    loginMethod: 0 | 1
    password: string
    organizationId: string
    hostingUrl: string
    redirect: string
  },
  { rejectValue: Error }
>(
  'auth/loginToOrganization',
  async (
    { loginData, loginMethod, password, organizationId, hostingUrl, redirect },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      if (hostingUrl) {
        setOrgUrl(hostingUrl)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL, hostingUrl)
      }

      const user = await AuthApi.loginToOrganizationRequest(
        loginData,
        loginMethod,
        '',
        password,
        organizationId
      )

      if (user.accessToken) {
        dispatch(setCurrentUser(user))
        saveToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, user.accessToken)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, user.refreshToken)

        const userLanguage = user.credentialsInfo.supportedLanguages.find(
          (lang) => lang.id === user.credentialsInfo.userLanguageId
        )?.name

        if (userLanguage) {
          const langCode = getLanguageCodeByName(userLanguage)

          saveToLocalStorage(LOCAL_STORAGE_KEYS.USER_LANGUAGE, JSON.stringify(langCode))
          i18n.changeLanguage(langCode)
        }

        if (!user.isMasterApi) {
          let orgMentions: OrganizationMentions

          const { data: brandingColors } = await AuthApi.organizationColorsRequest(organizationId)
          const {
            data: { mentions },
          } = await AuthApi.organizationMentionsRequest(organizationId)

          orgMentions = mentions

          if (!mentions) {
            const {
              ui: { masterOrganization },
            } = getState() as RootState

            const {
              data: { mentions },
            } = await AuthApi.masterOrganizationMentionsRequest(masterOrganization.id)
            orgMentions = mentions
          }

          if (orgMentions) {
            dispatch(setOrganizationMentions(orgMentions))
          }

          if (brandingColors && !brandingColors.StatusCode) {
            dispatch(setBrandingColors(brandingColors))
          }
        }
        history.push(redirect)
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
