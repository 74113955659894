import {
  AttachmentResponse,
  BaseTimeLineItem,
  ImageMedia,
  Media,
  TimelineItemType,
  VideoFromDeviceMedia,
  VideoMedia,
} from 'store/types'
import { isTimelineItemValid } from 'store/validators'
import { compressedMentionMapper } from './CompressedMentionMapper'
import { mentionMapper } from './TimelimeMentionMapper'
import { questionMapper } from './TimelineQuestionMapper'
import { resultMapper } from './TimelineResultMapper'
import { courseResultsMapper, seriesResultsMapper } from './TimelineSeriesResultMapper'
import { episodeMapper } from './TimelineEpisodeMapper'
import { MediaType } from 'store/types/timelineMedia'
import {
  CourseResultResponse,
  TimeLineItemResponse,
  PostResponse,
} from 'api/timeline/types/response'
import { postMapper } from './TimelinePostMapper'

import { EpisodeResponse } from 'api/episode/types/response'

const ITEM_QUESTION = 0
const ITEM_MENTION = 1
const ITEM_RESULT = 2
const ITEM_SERIES_RESULT = 3
const ITEM_COMRESSED_MENTION = 4
const ITEM_EPISODE = 5
const ITEM_COURSE_RESULT = 6
const ITEM_POST = 7
const ITEM_GROUPED_SERIES_RESULT = 8
const ITEM_GROUPED_EPISODE = 9

export const mapMedia = (attachment: AttachmentResponse | null): Media | null => {
  if (attachment) {
    if (attachment.videoId) {
      const media: VideoMedia = {
        type: MediaType.YOUTUBE_VIDEO,
        id: attachment.videoId,
        thumbnail: attachment.thumbnail,
      }
      return media
    }
    if (attachment.content) {
      const media: ImageMedia = {
        type: MediaType.IMAGE,
        source: attachment.content,
      }
      return media
    }
    if (attachment.giphy) {
      const media: ImageMedia = {
        type: MediaType.GIPHY,
        source: attachment.giphy,
      }
      return media
    }

    if (attachment.videoUrl) {
      const media: VideoFromDeviceMedia = {
        type: MediaType.VIDEO_FROM_DEVICE,
        source: attachment.videoUrl,
        thumbnail: attachment.thumbnail,
        subtitlesUrl: attachment.subtitlesUrl,
      }
      return media
    }
  }
  return null
}

const mapData = (data: TimeLineItemResponse) => {
  switch (data.type) {
    case ITEM_QUESTION:
      if (data.question) {
        return questionMapper(data.question)
      }
      break
    case ITEM_MENTION:
      if (data.mention) {
        return mentionMapper(data.mention)
      }
      break
    case ITEM_RESULT:
      if (data.questionResult) {
        return resultMapper(data.questionResult, data.date)
      }
      break
    case ITEM_SERIES_RESULT:
      if (data.seriesResult) {
        return seriesResultsMapper(data.seriesResult, data.wasRead)
      }
      break
    case ITEM_COMRESSED_MENTION:
      if (data.groupMention) {
        return compressedMentionMapper(data.groupMention, data.id)
      }
      break
    case ITEM_EPISODE:
      if (data.episode) {
        return episodeMapper(data.episode)
      }
      break
    case ITEM_COURSE_RESULT:
      if (data.seriesResult) {
        return courseResultsMapper(data.seriesResult as CourseResultResponse, data.wasRead)
      }
      break
    case ITEM_POST:
      if (data.post) {
        return postMapper(data.post as PostResponse)
      }
      break
    case ITEM_GROUPED_SERIES_RESULT:
      if (data.groupedSeriesResult) {
        return data.groupedSeriesResult
      }
      break
    case ITEM_GROUPED_EPISODE:
      if (data.groupedEpisode) {
        return data.groupedEpisode
      }
      break
    default:
      throw new Error('Provide Valid item type')
  }
}

export const mapTimeLineItemType = (type: number) => {
  switch (type) {
    case ITEM_QUESTION:
      return TimelineItemType.Question
    case ITEM_MENTION:
      return TimelineItemType.Mention
    case ITEM_RESULT:
      return TimelineItemType.AnsweredQuestion
    case ITEM_SERIES_RESULT:
      return TimelineItemType.SeriesResult
    case ITEM_COMRESSED_MENTION:
      return TimelineItemType.Compressed
    case ITEM_EPISODE:
      return TimelineItemType.Episode
    case ITEM_COURSE_RESULT:
      return TimelineItemType.CourseResult
    case ITEM_POST:
      return TimelineItemType.Post
    case ITEM_GROUPED_SERIES_RESULT:
      return TimelineItemType.GroupedSeriesResults
    case ITEM_GROUPED_EPISODE:
      return TimelineItemType.GroupedEpisodes
    default:
      throw new Error('Provide valid item type')
  }
}

const mapItem = (item: TimeLineItemResponse) => ({
  id: item.id,
  type: mapTimeLineItemType(item.type),
  data: mapData(item),
})

const mapTimeLineItems = (items: TimeLineItemResponse[]): BaseTimeLineItem<any>[] => {
  const flattedItems: BaseTimeLineItem<any>[] = []
  items.forEach((item) => {
    if (isTimelineItemValid(item)) {
      if (item.type !== ITEM_COMRESSED_MENTION) {
        flattedItems.push(mapItem(item))
      } else {
        if (item.groupMention) {
          flattedItems.push(compressedMentionMapper(item.groupMention, item.id))
        }
      }
    }
  })
  return flattedItems
  // return flattedItems.filter((value, index, self) => {
  //   const i = self.findIndex((item) => item.data.id === value.data.id)
  //   return i === index
  // })
}

const mapGroupedResults = (items: CourseResultResponse[], groupParentId: string) => {
  return items.map((item) => {
    return {
      id: item.id,
      type: TimelineItemType.CourseResult,
      data: courseResultsMapper(item, true, groupParentId),
    }
  })
}

const mapGroupedEpisodes = (items: EpisodeResponse[], groupParentId: string) => {
  return items.map((item) => {
    return {
      id: item.id,
      type: TimelineItemType.Episode,
      data: episodeMapper(item, groupParentId),
    }
  })
}

export { mapTimeLineItems, mapGroupedEpisodes, mapGroupedResults }
