import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { ITheme } from 'styles/types'

interface IProps {
  logo: string | null
  size?: number
  showBorderForDefaultLogo?: boolean
  defaultLogo: () => JSX.Element
  className?: string
  showBorderForLogo?: boolean
}

const Avatar: React.FC<IProps> = ({
  logo,
  size = 50,
  defaultLogo: DefaultLogo,
  showBorderForDefaultLogo = true,
  className,
  showBorderForLogo = false,
}) => {
  const [logoSrcError, setLogoSrcError] = useState(false)

  const showDefaultLogo = !logo || logoSrcError

  return (
    <AvatarBorder
      size={size}
      border={showBorderForLogo || (showDefaultLogo && showBorderForDefaultLogo)}
      className={className}
    >
      {!showDefaultLogo ? (
        <Logo src={logo} alt="avatar-logo" onError={() => setLogoSrcError(true)} />
      ) : (
        <DefaultLogo />
      )}
    </AvatarBorder>
  )
}

export default Avatar

const AvatarBorder = styled.div<{ size: number; border: boolean }>`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  ${({ border }) =>
    border &&
    css`
      border: 1px solid #fff;
    `}
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  will-change: transform;

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    transform: scale(0.9);
  }
`

export const Logo = styled.img`
  height: 100%;
  object-fit: cover;
  transform: translateZ(0);
`
