import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppRoutes, createPath } from 'router/routesConfig'
import { TimelineMention, TimelineMentionType } from 'store/types'
import styled, { ITheme } from 'styled-components/macro'
import ComplaintItem from './ComplaintItem'
import ComplimentItem from './ComplimentItem'
import SuggestionItem from './SuggestionItem'
import { TextAvenirNextRegular } from 'modules/common/components/Typography'
import { getScaledValue } from 'utils/scale'
import { useBrandingColors } from 'hooks/useBrandingColors'
import { PersonOutlinedSmall } from 'assets/svg'
import { getCurrentUser } from 'modules/common/helpers'
import TopicItem from './Topic'
import Avatar from 'modules/common/components/Avatar'
import ReactHtmlParser from 'react-html-parser'

interface IProps {
  mention: TimelineMention
  isGrouped?: boolean
}

const MentionItem: React.FC<IProps> = ({ mention, isGrouped }) => {
  const history = useHistory()
  const { text, frame } = useBrandingColors()
  const currentUser = getCurrentUser()

  const onMentionClick = (mentionId: string) => {
    history.push({
      pathname: createPath({
        path: AppRoutes.MENTION_CHAT,
        params: { mentionId },
      }),
      state: { from: history.location.pathname },
    })
  }

  const hasRecipient = (recipientId: string | undefined) => {
    return currentUser.credentialsInfo.id !== recipientId
  }

  const getMentionItem = () => ({
    [TimelineMentionType.Compliment]: (
      <ComplimentItem
        isGrouped={isGrouped}
        hasRecipient={hasRecipient(mention?.recipient?.id)}
        renderLastMessage={renderLastMessage}
        mention={mention}
      />
    ),
    [TimelineMentionType.Complaint]: (
      <ComplaintItem
        isGrouped={isGrouped}
        hasRecipient={hasRecipient(mention?.recipient?.id)}
        renderLastMessage={renderLastMessage}
        mention={mention}
      />
    ),
    [TimelineMentionType.Suggestion]: (
      <SuggestionItem
        isGrouped={isGrouped}
        hasRecipient={hasRecipient(mention?.recipient?.id)}
        renderLastMessage={renderLastMessage}
        mention={mention}
      />
    ),
    [TimelineMentionType.Topic]: (
      <TopicItem
        isGrouped={isGrouped}
        hasRecipient={hasRecipient(mention?.recipient?.id)}
        renderLastMessage={renderLastMessage}
        mention={mention}
      />
    ),
  })

  const renderLastMessage = () => {
    return (
      <LastMessage color={frame}>
        <LastMessageUserIcon
          logo={mention.lastMessage?.logo || null}
          size={42}
          showBorderForDefaultLogo={false}
          defaultLogo={PersonOutlinedSmall}
        />
        <LastMessageText textColor={text}>
          {ReactHtmlParser(mention.lastMessage?.message)}
        </LastMessageText>
      </LastMessage>
    )
  }

  return (
    <MentionWrapper onClick={() => onMentionClick(mention.id)}>
      {getMentionItem()[mention.mentionType]}
    </MentionWrapper>
  )
}

export default MentionItem

const MentionWrapper = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 2;
`
export const LastMessage = styled.div<{ color: string }>`
  position: relative;
  background-color: #0000000d;
  border-radius: 20px 8px 8px 20px;
  display: flex;
  align-items: center;
`

export const LastMessageText = styled(TextAvenirNextRegular)<{ textColor: string }>`
  font-size: ${getScaledValue(15, 'px')};
  color: ${({ textColor }) => textColor};
  display: inline-block;
  text-align: center;
  margin-left: ${getScaledValue(9, 'px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    margin: 10px 0;
    font-size: 15px;
  }
`

export const LastMessageUserIcon = styled(Avatar)`
  background: rgb(255 255 255 / 20%);
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    width: 32px;
    height: 32px;
  }
`
