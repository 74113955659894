import { SlidingMenuBackground } from 'assets/svg'
import Avatar from 'modules/common/components/Avatar'
import { TextCalibriRegular } from 'modules/common/components/Typography'
import styled, { css } from 'styled-components/macro'
import { ITheme } from 'styles/types'
import { getScaledValue } from 'utils/scale'

export const SlidingMenuContainer = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 100;
`

export const Background = styled(SlidingMenuBackground)`
  z-index: 100;
`

export const BurgerButton = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
`

export const AvatarIcon = styled(Avatar)``

export const DefaultLogo = styled.img`
  height: 70%;
  object-fit: cover;
  transform: translateZ(0);
  padding-left: 5px;
`

export const flexColumnCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MenuContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  ${flexColumnCenter}
`

export const AboutMe = styled.div`
  margin-bottom: 27px;
  cursor: pointer;
  ${flexColumnCenter}
`
export const AboutOthers = styled.div<{ isIndividual: boolean }>`
  margin-bottom: 27px;
  cursor: ${({ isIndividual }) => (isIndividual ? 'default' : 'pointer')};
  opacity: ${({ isIndividual }) => (isIndividual ? 0.5 : 1)};
  ${flexColumnCenter}
`
export const AboutOrganization = styled.div`
  cursor: pointer;
  margin-bottom: 44px;
  ${flexColumnCenter}
`
export const MenuTitle = styled(TextCalibriRegular)`
  text-transform: uppercase;
  font-size: ${getScaledValue(22, 'px')};
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 22px;
  }
`

export const MenuItem = styled(TextCalibriRegular)`
  font-size: ${getScaledValue(18, 'px')};
  margin-bottom: 27px;
  cursor: pointer;
  text-transform: uppercase;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 18px;
  }
`
