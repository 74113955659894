import { TextAvenirNextRegular, TextCalibriBold } from 'modules/common/components/Typography'
import styled, { ITheme } from 'styled-components'
import { getScaledValue } from 'utils/scale'

export const MentionHeader = styled(TextAvenirNextRegular)<{ textColor: string }>`
  font-size: ${getScaledValue(15, 'px')};
  color: ${({ textColor }) => textColor};
  display: block;
  text-align: center;
  margin-bottom: ${getScaledValue(6, 'px')};

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    margin: 10px 0;
    font-size: 15px;
  }
`

export const MentionText = styled(TextCalibriBold)<{ textColor: string }>`
  font-size: ${getScaledValue(16, 'px')};
  color: ${({ textColor }) => textColor};
  display: block;
  text-align: center;
  margin-top: 22px;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    margin: 10px 0;
    font-size: 16px;
  }
`

export const UserIcon = styled.div<{ imageUrl: string }>`
  border-radius: 50%;
  overflow: hidden;
  width: ${getScaledValue(42, 'px')};
  height: ${getScaledValue(42, 'px')};
  background-image: ${({ imageUrl }) => `url("${imageUrl}")`};
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    width: 42px;
    height: 42px;
  }
`
