import { createAsyncThunk } from '@reduxjs/toolkit'
import { Error, ParticipantGroupsSearch, ParticipantUsersSearch } from './types'
import { ParticipantGroupsPageSize } from './slice'
import { AxiosError } from 'axios'
import { PostsApi } from 'api/posts'
import { GroupType } from 'store/types/enums'

const searchQuery = (page: number, text: string, pageSize: number) => ({
  search: {
    fields: ['name'],
    value: text,
  },
  pager: {
    current: page,
    pageSize: pageSize || ParticipantGroupsPageSize,
  },
  sorter: [
    {
      field: 'name',
      order: 'asc',
    },
  ],
  filters: [],
})

export const getParticipantGroups = createAsyncThunk<
  ParticipantGroupsSearch,
  {
    searchText: string
    page: number
    isSearch?: boolean
    groupType: GroupType
    pageSize?: number
  },
  { rejectValue: Error }
>(
  'participant/groups',
  async ({ searchText, page, isSearch = false, groupType, pageSize }, { rejectWithValue }) => {
    const queryPageSize = pageSize ?? ParticipantGroupsPageSize

    try {
      const query: any = searchQuery(page, searchText, queryPageSize)

      const data = await PostsApi.getParticipantGroups(query, groupType, true)

      return {
        groups: data.data,
        total: data.total,
        totalWithChildren: data.totalWithChildren,
        nextPage: page + 1,
        pageSize: queryPageSize,
        isSearch,
        groupType,
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({
          message: error.response?.data.Message,
          groupType,
          isUsersError: false,
        })
      }
      throw err
    }
  }
)

export const getParticipantUsers = createAsyncThunk<
  ParticipantUsersSearch,
  {
    searchText: string
    page: number
    isSearch?: boolean
    pageSize?: number
  },
  { rejectValue: Error }
>(
  'participant/users',
  async ({ searchText, page, isSearch = false, pageSize }, { rejectWithValue }) => {
    const queryPageSize = pageSize ?? ParticipantGroupsPageSize

    try {
      const query: any = searchQuery(page, searchText, queryPageSize)

      const data = await PostsApi.getParticipants(query, true)

      return {
        users: data.data,
        total: data.total,
        nextPage: page + 1,
        pageSize: queryPageSize,
        isSearch,
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message, isUsersError: true })
      }
      throw err
    }
  }
)
