import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { TimelineApi } from 'api/timeline'
import {
  AnswerQuestionType,
  AppSettingsTypes,
  Error,
  PostAnswerRequestType,
  PostAnswerType,
  ShowQuestionInfoType,
} from './types'
import { BaseTimeLineItem, ServerTimeLineItemType, TimelineItemInfo } from 'store/types'
import { RootState } from 'store'
import { setIsAnswered, setIsSaved, setQuestionAnswer } from './slice'
import { AvailableAppSettingsResponse } from 'api/timeline/types/response'
import history from 'utils/history'
import { AppRoutes, createPath } from 'router/routesConfig'
import { getJourneyDetails } from '../calendar/thunks'

export const getTimelineItems = createAsyncThunk<
  { timelineItems: BaseTimeLineItem<any>[]; isLastPage: boolean; page: number },
  void,
  { rejectValue: Error }
>('timeline/getTimelineItems', async (_, { rejectWithValue }) => {
  try {
    const timeLineResponse = { data: [] as any, isLastPage: false }
    let page = 0

    while (timeLineResponse.data.length < 3 && !timeLineResponse.isLastPage) {
      const { data: timelineItems, isLastPage } = await TimelineApi.getTimelineRequest(page)

      timeLineResponse.data.push(...timelineItems)
      timeLineResponse.isLastPage = isLastPage

      page = page + 1
    }

    await TimelineApi.updateLastSeenTimelineRequest()

    return {
      timelineItems: timeLineResponse.data,
      isLastPage: timeLineResponse.isLastPage,
      page,
    }
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getNewTimelineItem = createAsyncThunk<
  BaseTimeLineItem<any> | null,
  { entityId: string; type: ServerTimeLineItemType },
  { rejectValue: Error }
>('timeline/getNewTimelineItem', async ({ entityId, type }, { rejectWithValue }) => {
  try {
    return await TimelineApi.getTimelineItemRequest(entityId, type)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getUpdatedTimelineItem = createAsyncThunk<
  BaseTimeLineItem<any> | null,
  { entityId: string; type: ServerTimeLineItemType },
  { rejectValue: Error }
>('timeline/getUpdatedTimelineItem', async ({ entityId, type }, { rejectWithValue }) => {
  try {
    return await TimelineApi.getTimelineItemRequest(entityId, type)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const loadMoreTimelineItems = createAsyncThunk<
  { timelineItems: BaseTimeLineItem<any>[]; isLastPage: boolean; page: number } | null,
  void,
  { rejectValue: Error }
>('timeline/loadMoreTimelineItems', async (_, { rejectWithValue, getState }) => {
  try {
    let timeLineResponse = { data: [] as any, isLastPage: false }

    let {
      timeline: { isLastPage, page },
    } = getState() as RootState

    if (isLastPage) return null

    while (timeLineResponse.data.length === 0 && !timeLineResponse.isLastPage) {
      timeLineResponse = await TimelineApi.getTimelineRequest(page)
      page += 1
    }

    const timelineItems = [...timeLineResponse.data]

    await TimelineApi.updateLastSeenTimelineRequest()

    return {
      timelineItems,
      isLastPage: timeLineResponse.isLastPage,
      page,
    }
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getQuestionPrivacySettings = createAsyncThunk<
  TimelineItemInfo,
  string,
  { rejectValue: Error }
>('timeline/getQuestionPrivacySettings', async (id, { rejectWithValue }) => {
  try {
    const { data } = await TimelineApi.questionPrivacySettingsRequest(id)
    return data
  } catch (err) {
    const error = err as AxiosError

    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getResultPrivacySettings = createAsyncThunk<
  TimelineItemInfo,
  ShowQuestionInfoType,
  { rejectValue: Error }
>('timeline/getResultPrivacySettings', async ({ id, resultType }, { rejectWithValue }) => {
  try {
    const { data } = await TimelineApi.resultPrivacySettingsRequest(id, resultType)
    return data
  } catch (err) {
    const error = err as AxiosError

    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const answerQuestion = createAsyncThunk<void, AnswerQuestionType, { rejectValue: Error }>(
  'timeline/answerQuestion',
  async ({ questionId, answer, value }, { rejectWithValue, dispatch }) => {
    try {
      await TimelineApi.postQuestionAnswerRequest(questionId, answer.id, value)

      dispatch(setQuestionAnswer({ answer, questionId }))
      dispatch(setIsAnswered(questionId))

      setTimeout(() => {
        dispatch(setIsSaved(questionId))
      }, 1000)
    } catch (err) {
      const error = err as AxiosError

      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getCertificate = createAsyncThunk<void, string, { rejectValue: Error }>(
  'timeline/getCertificate',
  async (seriesVersionId, { rejectWithValue }) => {
    try {
      await TimelineApi.getCertificateRequest(seriesVersionId)
    } catch (err) {
      const error = err as AxiosError

      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getAvailableAppSettings = createAsyncThunk<
  AvailableAppSettingsResponse[],
  void,
  { rejectValue: Error }
>('timeline/getAvailableAppSettings', async (_, { rejectWithValue }) => {
  try {
    return await TimelineApi.getAvailableAppSettingsRequest()
  } catch (err) {
    const error = err as AxiosError

    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const toggleHighlightPostItem = createAsyncThunk<string, string, { rejectValue: Error }>(
  'timeline/toggleHighlightPostItem',
  async (postId, { rejectWithValue }) => {
    try {
      await TimelineApi.hightLightPostRequest(postId)
      return postId
    } catch (err) {
      const error = err as AxiosError

      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const answerPost = createAsyncThunk<
  PostAnswerType,
  PostAnswerRequestType,
  { rejectValue: Error }
>(
  'timeline/answerPost',
  async ({ timelineItemId, answer, postId }, { dispatch, rejectWithValue, getState }) => {
    try {
      const userId = answer.postUserId!
      await TimelineApi.readPostRequest(postId)
      const { journeyId } = await TimelineApi.answerPostRequest(answer.id, userId)
      const state = getState() as RootState

      if (journeyId) {
        dispatch(getJourneyDetails({ journeyId, isUpcoming: true }))
        history.push({
          pathname: createPath({
            path: AppRoutes.CALENDAR,
            params: {
              screenTitle:
                state.timeline.availableAppSettings.find(
                  (item) => item.type === AppSettingsTypes.Calendar
                )?.title || 'Calendar',
            },
          }),
          state: {},
        })
      }

      return {
        timelineItemId,
        answer,
      }
    } catch (err) {
      const error = err as AxiosError

      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const readPost = createAsyncThunk<
  string,
  { postId: string; timelineItemId: string },
  { rejectValue: Error }
>('timeline/readPost', async ({ postId, timelineItemId }, { rejectWithValue }) => {
  try {
    await TimelineApi.readPostRequest(postId)
    return timelineItemId
  } catch (err) {
    const error = err as AxiosError

    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getGroupedResults = createAsyncThunk<
  {
    timelineItems: Awaited<ReturnType<typeof TimelineApi.getGroupedResultsRequest>>
    timelineItemId: string
  },
  { groupParentId: string; timelineItemId: string },
  { rejectValue: Error }
>('timeline/getGroupedResults', async ({ groupParentId, timelineItemId }, { rejectWithValue }) => {
  try {
    const timelineItems = await TimelineApi.getGroupedResultsRequest(groupParentId)
    return {
      timelineItems,
      timelineItemId,
    }
  } catch (err) {
    const error = err as AxiosError

    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getGroupedEpisodes = createAsyncThunk<
  {
    timelineItems: Awaited<ReturnType<typeof TimelineApi.getGroupedEpisodesRequest>>
    timelineItemId: string
  },
  { groupParentId: string; timelineItemId: string },
  { rejectValue: Error }
>('timeline/getGroupedEpisodes', async ({ groupParentId, timelineItemId }, { rejectWithValue }) => {
  try {
    const timelineItems = await TimelineApi.getGroupedEpisodesRequest(groupParentId)
    return {
      timelineItems,
      timelineItemId,
    }
  } catch (err) {
    const error = err as AxiosError

    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
