import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import screenful from 'screenfull'
import Controls from './Controls'
import styled from 'styled-components/macro'
import BottomControls from './BottomControls'
import { useSelector } from 'react-redux'
import { selectVideoProgress } from 'store/slices/episode/selectors'
import VideoProgressBar from 'modules/episode/EpisodeComponents/VideoProgressBar'
import { useAppDispatch } from 'store'
import { updateVideoProgress } from 'store/slices/episode/slice'
import { selectPlayingVideoId } from 'store/slices/UI/selectors'
import { setPlayingVideoId } from 'store/slices/UI/slice'
import useOnScreen from 'hooks/useOnScreen'

interface IProps {
  src: string
  itemId: string
  thumbnail?: string | null
  subtitles?: string | null
  isPage: boolean
  hasVideoProgress: boolean
  hasControls: boolean
  borderRadius?: string
  toggleOverlay?: (isShown: boolean) => void
}

const format = (seconds: number): string => {
  if (isNaN(seconds)) {
    return `00:00`
  }
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = date.getUTCSeconds().toString().padStart(2, '0')
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`
  }
  return `${mm}:${ss}`
}

let count = 0

const Player: React.FC<IProps> = ({
  src,
  itemId,
  thumbnail,
  subtitles,
  hasControls,
  hasVideoProgress,
  isPage,
  borderRadius = undefined,
  toggleOverlay,
}) => {
  const [state, setState] = useState({
    pip: false,
    playing: false,
    started: false,
    isEnded: false,
    controls: true,
    muted: false,
    subtitlesShown: false,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    volume: 1,
    loop: false,
    seeking: false,
    counter: 0,
  })

  const dispatch = useAppDispatch()
  const videoProgress = useSelector(selectVideoProgress(itemId, isPage))
  const playingVideoId = useSelector(selectPlayingVideoId)

  const playerRef = useRef<any>(null)
  const playerContainerRef = useRef<any>(null)
  const controlsRef = useRef<HTMLDivElement>(null)
  const bottomControlsRef = useRef<HTMLDivElement>(null)
  const didMountRef = useRef(false)

  useEffect(() => {
    didMountRef.current = true
  }, [])

  const {
    playing,
    muted,
    loop,
    playbackRate,
    pip,
    played,
    volume,
    subtitlesShown,
    isEnded,
    started,
  } = state

  const isOnScreen = useOnScreen(playerContainerRef)

  const currentTime = playerRef && playerRef.current ? playerRef.current.getCurrentTime() : '00:00'

  const duration = playerRef && playerRef.current ? playerRef.current.getDuration() : '00:00'

  const elapsedTime = format(currentTime)

  const totalDuration = format(duration)

  useEffect(() => {
    if (state.playing && !isOnScreen) {
      dispatch(setPlayingVideoId(''))
      setState({ ...state, playing: !state.playing, started: true })
    }
  }, [state.playing, isOnScreen])

  const togglePlay = () => {
    if (!state.playing) {
      dispatch(setPlayingVideoId(itemId))
    }

    if (state.playing) {
      dispatch(setPlayingVideoId(''))
    }

    setState({ ...state, playing: !state.playing, started: true })

    if (toggleOverlay) {
      toggleOverlay(state.playing)
    }
  }

  const handleProgress = (changeState: any) => {
    const progressUpdate = (1 / duration) * 111

    dispatch(updateVideoProgress({ id: itemId, progressUpdate, isPage }))

    if (count > 3 && controlsRef && controlsRef.current) {
      controlsRef.current.style.visibility = 'visible'
      count = 0
    }
    if (controlsRef && controlsRef.current && controlsRef.current.style.visibility === 'visible') {
      count += 1
    }
    if (!state.seeking) {
      setState({ ...state, ...changeState })
    }
  }

  const handleSeekChange = (newValue: number) => {
    setState({ ...state, played: newValue / 100 })
  }

  const handleSeekMouseUp = (newValue: number) => {
    setState({ ...state, seeking: false })
    playerRef.current.seekTo(newValue / 100, 'fraction')
  }

  const handleVolumeSeekDown = (newValue: number) => {
    setState({ ...state, seeking: false, volume: newValue / 100 })
  }
  const handleVolumeChange = (newValue: number) => {
    setState({
      ...state,
      volume: newValue / 100,
      muted: newValue === 0,
    })
  }

  const toggleFullScreen = () => {
    if (screenful.isEnabled) {
      screenful.toggle(playerContainerRef.current)
    }
  }

  const handleMouseMove = () => {
    if (controlsRef && controlsRef.current) {
      controlsRef.current.style.visibility = 'visible'
      count = 0
    }

    if (bottomControlsRef && bottomControlsRef.current) {
      bottomControlsRef.current.style.opacity = '1'
      count = 0
    }
  }

  const hanldeMouseLeave = () => {
    if (controlsRef && controlsRef.current && playing) {
      controlsRef.current.style.visibility = 'hidden'
      count = 0
    }

    if (bottomControlsRef && bottomControlsRef.current) {
      bottomControlsRef.current.style.opacity = '0'
      count = 0
    }
  }

  const handlePlaybackRate = (rate: number) => {
    setState({ ...state, playbackRate: rate })
  }

  const hanldeMute = () => {
    setState({ ...state, muted: !state.muted })
  }

  const handleReplay = () => {
    setState({ ...state, played: 0, playing: true, isEnded: false, seeking: false })
  }

  const toggleSubtitles = () => {
    setState({ ...state, subtitlesShown: !state.subtitlesShown })

    /* eslint-disable-next-line prefer-destructuring */
    const videoWrapper = document.getElementsByClassName(itemId)[0]

    if (videoWrapper) {
      const video = videoWrapper.getElementsByTagName('video')
      if (subtitles) {
        if (subtitlesShown) {
          video[0].textTracks[0].mode = 'hidden'
        } else {
          video[0].textTracks[0].mode = 'showing'
        }
      }
    }
  }

  const handleEnded = () => {
    setState({ ...state, isEnded: true, playing: false })
  }

  return (
    <>
      <PlayerWrapper
        borderRadius={borderRadius}
        onMouseMove={handleMouseMove}
        onMouseLeave={hanldeMouseLeave}
        ref={playerContainerRef}
        onClick={(e) => e.stopPropagation()}
        isFullScreen={screenful.isEnabled && screenful.isFullscreen}
      >
        {!started && thumbnail && <Thumbnail src={thumbnail} />}

        <ReactPlayer
          ref={playerRef}
          className={itemId}
          width="100%"
          height="100%"
          url={src}
          pip={pip}
          playing={playingVideoId === itemId ? playing : false}
          controls={false}
          loop={loop}
          playIcon={<div />}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onProgress={didMountRef.current ? handleProgress : undefined}
          progressInterval={1000}
          onEnded={handleEnded}
          config={{
            file: {
              attributes: {
                crossOrigin: 'anonymous',
              },
              tracks: [
                {
                  kind: 'subtitles',
                  src: subtitles ? subtitles : '',
                  srcLang: 'en',
                  default: false,
                  label: 'eng',
                },
              ],
            },
          }}
        />
        {hasControls && (
          <ControlsContainer>
            <Controls
              ref={controlsRef}
              playing={playingVideoId === itemId ? playing : false}
              onTogglePlay={togglePlay}
              isEnded={isEnded}
              onReplay={handleReplay}
            />
            <BottomControls
              borderRadius={borderRadius}
              ref={bottomControlsRef}
              playing={playingVideoId === itemId ? playing : false}
              played={played}
              elapsedTime={elapsedTime}
              totalDuration={totalDuration}
              playbackRate={playbackRate}
              isSubtitlesShown={subtitlesShown}
              isEnded={isEnded}
              muted={muted}
              volume={volume}
              onSeek={handleSeekChange}
              onSeekMouseUp={handleSeekMouseUp}
              onTogglePlay={togglePlay}
              onReplay={handleReplay}
              onMute={hanldeMute}
              onVolumeChange={handleVolumeChange}
              onVolumeSeekDown={handleVolumeSeekDown}
              onPlaybackRateChange={handlePlaybackRate}
              onToggleFullScreen={toggleFullScreen}
              toggleSubtitles={toggleSubtitles}
            />
          </ControlsContainer>
        )}
      </PlayerWrapper>
      {hasVideoProgress && <VideoProgressBar percentPlayed={videoProgress} />}
    </>
  )
}

export default Player

const PlayerWrapper = styled.div<{ isFullScreen: boolean; borderRadius?: string }>`
  height: ${({ isFullScreen }) => (isFullScreen ? '100%' : '215px')};
  position: relative;
  overflow: hidden;
  border-radius: ${({ borderRadius }) => borderRadius || '15px;'};
  background-color: #000;

  & > div > video::cue {
    background-color: #000;
  }

  & > div > video {
    object-fit: cover;
  }
`
const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 15px 15px 5px 15px;
`

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
